import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { Link } from 'react-router-dom';
import BottomNavigation from '../components/BottomNavigation';
import BackButton from '../components/BackButton';

const Stores = () => {
  const [stores, setStores] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredStores, setFilteredStores] = useState([]);

  useEffect(() => {
    const db = getDatabase();
    const storesRef = ref(db, 'stores');

    const fetchStores = () => {
      onValue(storesRef, (snapshot) => {
        const storesData = snapshot.val();
        if (storesData) {
          const storesList = Object.keys(storesData).map(key => ({
            id: key,
            ...storesData[key]
          }));
          setStores(storesList);
          setFilteredStores(storesList);
        } else {
          setStores([]);
          setFilteredStores([]);
        }
      }, (error) => {
        console.error('Error fetching stores:', error);
      });
    };

    fetchStores();
  }, []);

  useEffect(() => {
    setFilteredStores(
      stores.filter(store =>
        store.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, stores]);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 p-4 bg-gray-900 text-white shadow-md">
        <div className="container mx-auto flex items-center">
          <BackButton/>
          <h1 className="ml-2 text-red-600 text-xl font-bold sm:text-2xl">Tiendas</h1>
        </div>
      </header>

      {/* Search Bar */}
      <div className="pt-20 p-4"> {/* Ajustamos el padding-top para evitar el overlap */}
        <input
          type="text"
          placeholder="Buscar tiendas..."
          className="w-full p-3 rounded-lg border border-gray-300 bg-white text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Main Content */}
      <main className="flex-grow p-4">
        {filteredStores.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {filteredStores.map((store) => (
              <div key={store.id} className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center transition-transform transform hover:scale-105">
                <img
                  src={store.logoUrl}
                  alt={store.name}
                  className="w-24 h-24 object-cover rounded-full mb-4 shadow-md"
                />
                <h2 className="text-xl font-bold text-gray-800 mb-2">{store.name}</h2>
                <p className="text-gray-600 text-center mb-2">
                  <strong>Dirección:</strong> {store.location}
                </p>
                <p className="text-gray-600 text-center mb-4">
                  <strong>Contacto:</strong> {store.contact}
                </p>
                <Link to={`/store/${store.id}`}>
                  <button className="px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors">
                    Ver más
                  </button>
                </Link>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-700 p-4 bg-white rounded-lg shadow-md">
            No se encontraron tiendas que coincidan con el término de búsqueda.
          </div>
        )}
      </main>
      {/* <BottomNavigation /> */}
    </div>
  );
};

export default Stores;
