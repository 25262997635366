import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, database } from '../utils/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, set } from 'firebase/database';

export default function CreateAccount() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    setError(''); // Limpiar cualquier mensaje de error anterior

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        // Guardar el UUID en localStorage
        localStorage.setItem('userUUID', user.uid);

        // Guardar datos del usuario en Firebase
        set(ref(database, 'users/' + user.uid), {
          username: username,
          email: email,
          uid: user.uid
        });

        navigate('/login'); // Redirige a la página de inicio o a la que desees
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          setError('El correo electrónico ya está en uso.');
        } else {
          setError('Ocurrió un error al registrar el usuario. Inténtalo de nuevo.');
        }
        console.error('Error registrando el usuario:', error);

        // Limpiar el mensaje de error después de 3 segundos
        setTimeout(() => {
          setError('');
        }, 3000);
      });
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 bg-cover bg-center"
      style={{ backgroundImage: 'url("https://www.prensalibre.com/wp-content/uploads/2022/03/Beneficios-de-ir-al-mar-01.jpg?quality=52")' }}
    >
      <div
        className="w-full max-w-lg p-10 space-y-8 bg-white bg-opacity-90 rounded-3xl shadow-2xl bg-cover bg-center"
        style={{ backgroundImage: 'url("https://www.infobae.com/new-resizer/l_GCEUPlOBY1grd8mHBgNClf7xg=/768x1024/filters:format(webp):quality(85)/cloudfront-us-east-1.images.arcpublishing.com/infobae/T7QOQPWQYBD2TJMZ2UXJ43RTMA.jpg")' }}
      >
        <h1 className="text-4xl font-extrabold text-center text-gray-900">
          ¡Crea tu cuenta! 
        </h1>
        <form onSubmit={handleSubmit}>
          {error && (
            <div className="mb-4 p-4 text-red-600 bg-red-100 border border-red-400 rounded-lg shadow-md animate-bounce">
              {error}
            </div>
          )}
          <div className="mb-6">
            <label htmlFor="username" className="block mb-2 text-sm font-semibold text-gray-800">
              Nombres
            </label>
            <input
              type="text"
              id="username"
              className="w-full px-5 py-3 text-gray-700 bg-gray-100 border border-gray-200 rounded-lg shadow-sm focus:outline-none focus:ring-4 focus:ring-purple-400"
              placeholder="Nombres"
              value={username}
              onChange={(e) => {
                setError(''); // Limpiar mensaje de error al modificar el campo
                setUsername(e.target.value);
              }}
              required
            />
          </div>
          <div className="mb-6">
            <label htmlFor="email" className="block mb-2 text-sm font-semibold text-gray-800">
              Correo Electrónico
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-5 py-3 text-gray-700 bg-gray-100 border border-gray-200 rounded-lg shadow-sm focus:outline-none focus:ring-4 focus:ring-purple-400"
              placeholder="correo@ejemplo.com"
              value={email}
              onChange={(e) => {
                setError(''); // Limpiar mensaje de error al modificar el campo
                setEmail(e.target.value);
              }}
              required
            />
          </div>
          <div className="mb-8">
            <label htmlFor="password" className="block mb-2 text-sm font-semibold text-gray-800">
              Contraseña
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-5 py-3 text-gray-700 bg-gray-100 border border-gray-200 rounded-lg shadow-sm focus:outline-none focus:ring-4 focus:ring-purple-400"
              placeholder="********"
              value={password}
              onChange={(e) => {
                setError(''); // Limpiar mensaje de error al modificar el campo
                setPassword(e.target.value);
              }}
              required
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="w-full px-6 py-3 text-lg font-medium text-white transition-all duration-200 bg-purple-600 rounded-full shadow-lg hover:bg-purple-700 focus:outline-none focus:ring-4 focus:ring-purple-300"
            >
              Registrarse
            </button>
          </div>
          <div className="mt-6 text-center">
            <Link to="/login" className=" text-white text-1xl">
              ¿Ya tienes una cuenta? <span className="text-red-700">Inicia sesión</span>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
  
}
