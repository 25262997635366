import React, { useState, useEffect, useCallback } from "react";
import { ref, onValue, getDatabase } from "firebase/database";
import { app } from "../../utils/firebase"; // Asegúrate de que esta ruta sea correcta
import { useNavigate } from "react-router-dom";
import Image from "../layout/Image";

export default function Carousel() {
  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const database = getDatabase(app);

  useEffect(() => {
    const slidesRef = ref(database, "settings/home/slider");
    const unsubscribe = onValue(slidesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const slidesArray = Object.entries(data).map(([key, value]) => ({
          id: key,
          ...value,
        }));
        setSlides(slidesArray);
      }
    });

    return () => unsubscribe();
  }, [database]);

  const handlePrev = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  }, [slides.length]);

  const handleNext = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  }, [slides.length]);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, 5000); // Cambia de imagen cada 5 segundos

    return () => clearInterval(timer);
  }, [handleNext]);

  const handleSlideClick = (path) => {
    if (path.startsWith("/")) {
      // Es una ruta interna, usamos navigate
      navigate(path);
    } else if (path.startsWith("http://") || path.startsWith("https://")) {
      // Es una URL externa, abrimos en una nueva pestaña
      window.open(path, "_blank", "noopener,noreferrer");
    } else {
      // Si no es ni una ruta interna ni una URL externa, asumimos que es una ruta interna
      navigate("/" + path);
    }
  };

  return (
    <div className="relative w-full pt-0 py-0 pb-0">
      {/* Carousel wrapper */}
      <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className={`absolute top-0 left-0 w-full h-full transition-opacity duration-500 ease-in-out ${
              index === currentIndex ? "opacity-100" : "opacity-0"
            }`}
            onClick={() => handleSlideClick(slide.path)}
          >
            <Image
              src={slide.url}
              className="object-cover cursor-pointer"
              alt={`Slide ${index + 1}`}
              w={"100%"}
              h={"100%"}
            />
          </div>
        ))}
      </div>

      {/* Slider indicators */}
      <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
        {slides.map((_, index) => (
          <button
            key={index}
            type="button"
            className={`w-4 h-4 rounded-full ${
              index === currentIndex ? "bg-blue-600" : "bg-gray-400"
            } transition-colors duration-300`}
            aria-current={index === currentIndex}
            aria-label={`Slide ${index + 1}`}
            onClick={() => setCurrentIndex(index)}
          ></button>
        ))}
      </div>

      {/* Slider controls */}
      <button
        type="button"
        className="absolute top-1/2 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group -translate-y-1/2"
        onClick={handlePrev}
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/60 shadow-lg group-hover:bg-white/80 transition-colors duration-300">
          <svg
            className="w-4 h-4 text-gray-800 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 1 1 5l4 4"
            />
          </svg>
          <span className="sr-only">Previous</span>
        </span>
      </button>
      <button
        type="button"
        className="absolute top-1/2 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group -translate-y-1/2"
        onClick={handleNext}
      >
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/60 shadow-lg group-hover:bg-white/80 transition-colors duration-300">
          <svg
            className="w-4 h-4 text-gray-800 rtl:rotate-180"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 6 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 9 4-4-4-4"
            />
          </svg>
          <span className="sr-only">Next</span>
        </span>
      </button>
    </div>
  );
}
