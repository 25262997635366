import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { database } from '../../utils/firebase';
import { ref, onValue } from 'firebase/database';
import { FaLock, FaCreditCard, FaRegClock } from 'react-icons/fa';

const stripePromise = loadStripe('pk_test_51OuqDYKdMXJUbatcTog0yDnOUcarbzR5PQUzp8ZdrZQVql2qL4mlYNbCkx5EyotKBx6iMypfe3x6ALQ58GLqkaoS00cNUbEQnQ');

const CardSection = ({ error }) => (
  <div className="mb-6">
    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="card-element">
      Información de la tarjeta
    </label>
    <div className="border rounded-md p-3 bg-white">
      <CardElement options={{style: {base: {fontSize: '16px'}}}} />
    </div>
    {error && <div className="text-red-500 text-sm mt-2">{error}</div>}
  </div>
);

const CheckoutForm = ({ course }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(error.message);
      setProcessing(false);
    } else {
      try {
        const response = await fetch('/api/process-payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            payment_method_id: paymentMethod.id,
            amount: course.price * 100,
          }),
        });
        const result = await response.json();
        if (result.success) {
          console.log('Pago exitoso');
          // Redireccionar al usuario o mostrar un mensaje de éxito
        } else {
          setError('Hubo un problema al procesar el pago');
        }
      } catch (err) {
        setError('Error en la conexión');
      }
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-8">
      <CardSection error={error} />
      <button 
        type="submit" 
        disabled={!stripe || processing}
        className={`w-full bg-blue-600 text-white font-bold py-3 px-4 rounded-lg 
                    transition duration-300 ease-in-out ${processing ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
      >
        {processing ? 'Procesando...' : `Pagar $${course.price}`}
      </button>
    </form>
  );
};

const PaymentPage = () => {
  const [course, setCourse] = useState(null);
  const { courseId } = useParams();

  useEffect(() => {
    const courseRef = ref(database, `courses/${courseId}`);
    onValue(courseRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCourse(data);
      }
    });
  }, [courseId]);

  if (!course) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-lg mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-blue-600 text-white py-4 px-6">
          <h2 className="text-2xl font-bold">Checkout</h2>
        </div>
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-semibold">{course.title}</h3>
            <span className="text-2xl font-bold text-blue-600">${course.price}</span>
          </div>
          <Elements stripe={stripePromise}>
            <CheckoutForm course={course} />
          </Elements>
          <div className="mt-8 border-t pt-6">
            <h4 className="text-lg font-semibold mb-4">¿Por qué elegirnos?</h4>
            <ul className="space-y-3">
              <li className="flex items-center text-gray-600">
                <FaLock className="mr-2 text-green-500" /> Pago 100% seguro
              </li>
              <li className="flex items-center text-gray-600">
                <FaCreditCard className="mr-2 text-green-500" /> Aceptamos todas las tarjetas principales
              </li>
              <li className="flex items-center text-gray-600">
                <FaRegClock className="mr-2 text-green-500" /> Acceso inmediato después del pago
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;