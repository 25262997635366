import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import BottomNavigation from '../components/BottomNavigation';
import { Link } from 'react-router-dom';
import BackButton from '../components/BackButton';

export default function Search() {
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setResults([]);
      return;
    }

    const fetchResults = async () => {
      setLoading(true);
      setResults([]); // Limpiar resultados previos

      try {
        const db = getDatabase();
        const storesRef = ref(db, 'stores');

        onValue(storesRef, (snapshot) => {
          const storesData = snapshot.val();
          if (storesData) {
            const lowerCaseQuery = searchQuery.toLowerCase();
            const storesArray = Object.entries(storesData).map(([key, value]) => ({
              id: key,
              ...value
            }));

            const filteredResults = [];

            storesArray.forEach(store => {
              // Buscar en los productos de la tienda
              const productMatches = store.procut
                ? Object.values(store.procut).filter(product =>
                    product.nameProduct.toLowerCase().includes(lowerCaseQuery)
                  )
                : [];

              productMatches.forEach(product => {
                filteredResults.push({
                  id: product.id,
                  storeId: store.id,
                  name: product.nameProduct,
                  imgUrl: product.imgUrl,
                  price: product.price,
                  storeName: store.name,
                  isProduct: true,
                });
              });

              // Si el nombre de la tienda coincide, añadirla a los resultados
              if (store.name.toLowerCase().includes(lowerCaseQuery)) {
                filteredResults.push({
                  id: store.id,
                  name: store.name,
                  imgUrl: store.logoUrl,
                  location: store.location,
                  isStore: true,
                });
              }
            });

            setResults(filteredResults);
          } else {
            setResults([]);
          }
          setLoading(false);
        }, (error) => {
          console.error('Error fetching stores:', error);
          setLoading(false);
        });
      } catch (error) {
        console.error('Error in fetching data:', error);
        setLoading(false);
      }
    };
 
    fetchResults();
  }, [searchQuery]);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600">
      <header className="fixed top-0 left-0 right-0 z-50 p-4 bg-gray-900 text-white shadow-md">
        <div className="container mx-auto flex items-center">
          <BackButton/>
          <h1 className="ml-2 text-red-600 text-xl font-bold sm:text-2xl">Buscar</h1>
        </div>
      </header>
      <div className="flex-grow p-4 pt-20">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Busca tiendas o productos..."
          className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <div className="mt-6">
          {loading ? (
            <p className="text-white text-center">Cargando...</p>
          ) : results.length > 0 ? (
            <div className="space-y-4">
              {results.map((item, index) => (
                <Link
                  key={index}
                  to={
                    item.isStore
                      ? `/store/${item.id}`
                      : `/store/${item.storeId}/product/${item.id}`
                  }
                  className="flex items-center bg-white p-4 rounded-lg shadow-md hover:bg-gray-100 transition-colors"
                >
                  <img
                    src={item.imgUrl}
                    alt={item.name}
                    className="w-16 h-16 mr-4 rounded-lg object-cover"
                  />
                  <div className="flex-1">
                    <h2 className="text-xl font-semibold text-gray-800">{item.name}</h2>
                    {item.isStore ? (
                      <p className="text-gray-600">{item.location}</p>
                    ) : (
                      <p className="text-gray-600">${item.price}</p>
                    )}
                    {!item.isStore && <p className="text-gray-500">Tienda: {item.storeName}</p>}
                  </div>
                </Link>
              ))}
            </div>
          ) : searchQuery.trim() !== '' ? (
            <div className="text-center p-4 bg-white text-gray-800 rounded-lg shadow-md">
              <p className="text-lg font-medium">No se encontraron resultados.</p>
              <p className="text-gray-600 mt-2">Intenta con diferentes términos de búsqueda.</p>
            </div>
          ) : null}
        </div>
      </div>
      <footer className="bg-gray-900 text-white py-3 sm:py-4">
        <BottomNavigation />
      </footer>
    </div>
  );
}
