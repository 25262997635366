import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = Boolean(localStorage.getItem('userUUID'));

  // Si está autenticado, renderiza el componente; de lo contrario, redirige a '/'
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default ProtectedRoute;
