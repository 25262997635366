import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Settings } from './pages/Settings';
import CreateAccount from './app/CreateAccount';
import HomeU from './app/HomeU';
import Login from './app/Login';
import Stores from './app/Stores';
import StoreDetails from './app/StoreDetails';
import ProductDetails from './app/ProductDetails';
import Landing from './app/Landing';
import ProtectedRoute from './components/ProtectedRoute';
import Search from './app/Search';
import UserProfile from './app/UserProfile';
import ProductsList from './app/ProductsList';
import EmergencyRequest from './app/EmergencyRequest';
import HistoryEmergency from './app/HistoryEmergency';
import RadioOnline from './app/RadioOnline';
import UserSupport from './app/UserSupport';
import Club from './app/Club';
import HomeClub from './app/HomeClub';
import About from './app/About';
import ServicesView from './app/ServicesView';
import ServiceDetails from './app/ServiceDetails';
import AdminStore from './admin/AdminStore';
import AdminDashboard from './admin/AdminDashboard';
import SupportChat from './app/chat/SupportChat';
import AdminSupportChat from './app/chat/AdminSupportChat';
import Home from './app/learn/Home';
import CourseDetail from './app/learn/CourseDetail';
import PaymentPage from './app/Checkout/CheckoutForm';
import ForgotPassword from './components/ForgotPassword';
import PrivacyPolicy from './components/footer/PrivacyPolicy';
import TermsOfService from './components/footer/TermsOfService';
import EpaycoCheckout from './components/checkout/EpaycoCheckout';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/home" element={<ProtectedRoute component={HomeU} />} />
        <Route path="/store/:id" element={<ProtectedRoute component={StoreDetails} />} />
        <Route path="/stores" element={<ProtectedRoute component={Stores} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/settings" element={<ProtectedRoute component={Settings} />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/store/:storeId/product/:id" element={<ProtectedRoute component={ProductDetails} />} />
        <Route path="/store/:storeId/service/:serviceId" element={<ProtectedRoute component={ServiceDetails} />} />
        <Route path="/search" element={<Search />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/all-products" element={<ProductsList />} />
        <Route path="/emergency" element={<EmergencyRequest />} />
        <Route path="/history-emergency" element={<HistoryEmergency />} />
        <Route path="/radio-online" element={<ProtectedRoute component={RadioOnline} />} />
        <Route path="/support" element={<ProtectedRoute component={SupportChat} />} />
        <Route path="/admin-support" element={<ProtectedRoute component={AdminSupportChat} />} />
        <Route path="/learn" element={<ProtectedRoute component={Home} />} />
        <Route path="/course/:courseId" element={<CourseDetail />} />
        <Route path="/payment/:courseId" element={<PaymentPage  />} />
        <Route path="/home-club" element={<ProtectedRoute component={HomeClub} />} />
        <Route path="/services" element={<ProtectedRoute component={ServicesView} />} />
        <Route path="/about" element={<About />} />
        <Route path="/forgot-password" element={<ForgotPassword  />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy  />} />
        <Route path="/terms-of-service" element={<TermsOfService  />} />
        <Route path="/pay" element={<EpaycoCheckout  />} />


        {/* for admin stores */}
        <Route path="/admin-store" element={<AdminStore />} /> 
        <Route path="/admin" element={<AdminDashboard />} /> 

      </Routes>
    </Router>
  );
}

export default App;
