import React, { useState, useEffect, useMemo } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../utils/Loading";
import BottomNavigation from "../components/BottomNavigation";
import { TiArrowBack } from "react-icons/ti";

export default function ProductsList() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook para manejar la navegación

  const handleBackClick = () => {
    navigate(-1); // Navega a la página anterior
  };
  
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      setProducts([]); // Limpiar productos previos

      try {
        const db = getDatabase();
        const storesRef = ref(db, "stores");

        onValue(
          storesRef,
          (snapshot) => {
            const storesData = snapshot.val();
            if (storesData) {
              const productsArray = [];

              Object.entries(storesData).forEach(([storeId, store]) => {
                if (store.procut) {
                  Object.values(store.procut).forEach((product) => {
                    productsArray.push({
                      id: product.id,
                      name: product.nameProduct,
                      description: product.description,
                      price: product.price,
                      imgUrl: product.imgUrl,
                      storeId: storeId, // Usar storeId para la tienda
                      storeName: store.name,
                    });
                  });
                }
              });

              setProducts(productsArray);
              setFilteredProducts(productsArray);
            } else {
              setProducts([]);
              setFilteredProducts([]);
            }
            setLoading(false);
          },
          (error) => {
            console.error("Error fetching stores:", error);
            setError(error.message);
            setLoading(false);
          }
        );
      } catch (error) {
        console.error("Error in fetching data:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const filteredResults = useMemo(() => {
    const lowercasedTerm = searchTerm.toLowerCase();

    return products.filter((product) => {
      const matchesSearchTerm = product.name
        .toLowerCase()
        .includes(lowercasedTerm);
      const matchesStore = !selectedStore || product.storeId === selectedStore;

      return matchesSearchTerm && matchesStore;
    });
  }, [searchTerm, selectedStore, products]);

  useEffect(() => {
    setFilteredProducts(filteredResults);
  }, [filteredResults]);

  if (loading)
    return (
      <div className="text-center p-6">
        <Loading />
      </div>
    );

  if (error)
    return <div className="text-center p-6 text-red-600">Error: {error}</div>;

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600">
      <header className="fixed top-0 left-0 right-0 z-50 p-4 bg-gray-900 text-white shadow-md">
        <div className="container mx-auto flex items-center justify-start space-x-4">
          <button onClick={handleBackClick} className="text-white text-xl">
            <TiArrowBack />
          </button>
          <h1 className="text-red-600 text-xl font-bold sm:text-2xl">
            Lista de Productos
          </h1>
        </div>
      </header>

      <div className="pt-20 px-2">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Buscar productos..."
            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-4 focus:ring-blue-400"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <select
            className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-4 focus:ring-blue-400"
            value={selectedStore}
            onChange={(e) => setSelectedStore(e.target.value)}
          >
            <option value="">Todas las tiendas</option>
            {Array.from(new Set(products.map((p) => p.storeId))).map(
              (storeId) => (
                <option key={storeId} value={storeId}>
                  {products.find((p) => p.storeId === storeId)?.storeName ||
                    "Tienda Desconocida"}
                </option>
              )
            )}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-4">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <Link
              key={product.id}
              to={`/store/${product.storeId}/product/${product.id}`}
              className="border border-gray-200 rounded-lg p-4 bg-white shadow-sm hover:shadow-lg transition-shadow duration-300"
            >
              <div className="flex items-center mb-4">
                <div className="w-24 h-24 bg-gray-200 rounded-lg overflow-hidden flex-shrink-0">
                  <img
                    src={product.imgUrl || "path/to/placeholder-image.jpg"}
                    alt={product.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="ml-4 flex-1">
                  <h2 className="text-xl font-semibold text-gray-800">
                    {product.name}
                  </h2>
                  <p className="mt-2 text-lg font-bold text-gray-900">
                    ${product.price || "Precio no disponible"}
                  </p>
                  <p className="text-gray-500">Tienda: {product.storeName}</p>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="text-center text-gray-600 bg-gray-100 p-4 rounded-lg shadow-md">
            <p className="text-lg font-medium">No se encontraron productos.</p>
            <p className="text-gray-500 mt-2">
              Intenta con diferentes términos de búsqueda.
            </p>
          </div>
        )}
        <BottomNavigation />
      </div>
    </div>
  );
}
