import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import Loading from '../utils/Loading'; // Asegúrate de que la ruta es correcta

const ServiceDetails = () => {
  const { storeId, serviceId } = useParams(); // Obtén storeId y serviceId de la URL
  const [service, setService] = useState(null);
  const [store, setStore] = useState(null); // Estado para la tienda
  const [loading, setLoading] = useState(true); // Estado de carga

  useEffect(() => {
    const db = getDatabase();

    // Obtener información del servicio
    const serviceRef = ref(db, `stores/${storeId}/service/${serviceId}`);
    onValue(serviceRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setService(data);
      } else {
        setService(null);
      }
      setLoading(false); // Detener la carga cuando se obtiene la información
    }, (error) => {
      console.error('Error fetching service data:', error);
      setLoading(false); // Detener la carga en caso de error
    });

    // Obtener información de la tienda
    const storeRef = ref(db, `stores/${storeId}`);
    onValue(storeRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setStore(data);
      } else {
        setStore(null);
      }
    }, (error) => {
      console.error('Error fetching store data:', error);
    });
  }, [storeId, serviceId]);

  if (loading) {
    return <Loading />;
  }

  if (!service || !store) {
    return <p className="text-white">No se encontró el servicio o la tienda.</p>;
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="bg-gray-900 p-6 rounded-md shadow-md">
        <h2 className="text-3xl font-bold text-red-500 mb-4">{service.name}</h2>
        <img
          src={service.imgUrl}
          alt={service.name}
          className="w-full h-80 object-cover rounded-md mb-6"
        />
        <p className="text-gray-200 mb-4">{service.description}</p>
        <p className="text-gray-200 mb-1 flex items-center">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
          Ubicación: {store.location}
        </p>
        <p className="text-gray-200 mb-1 flex items-center">
          <FontAwesomeIcon icon={faPhone} className="mr-2" />
          Contacto: {store.contact}
        </p>
        <p className="text-gray-200 mb-1">Tienda: {store.name}</p>
        <p className="text-yellow-400 text-2xl font-bold mb-4">${service.price.toLocaleString()}</p>
        <p className="text-gray-300 mb-6">Stock disponible: {service.stock}</p>
        <div className="flex space-x-4 justify-center">
          <button className="px-4 py-2 bg-green-500 text-white font-semibold rounded-md hover:bg-sky-700 transition">
            Comprar
          </button>
          <button className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-purple-800 transition">
            Reservar
          </button>
          <button className="px-4 py-2 bg-red-500 text-white font-semibold rounded-md hover:bg-yellow-600 transition">
            Ver tienda
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
