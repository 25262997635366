import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetchAppData from "../hooks/useFetchAppData";
import Loading from "../utils/Loading";
import { motion } from "framer-motion";
import {
  FaBlog,
  FaCheckCircle,
  FaFacebook,
  FaInstagram,
  FaRocket,
  FaShieldAlt,
  FaSpotify,
  FaStar,
  FaThumbsUp,
  FaTiktok,
  FaUsers,
} from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";

function Landing() {
  const { data, loading, error } = useFetchAppData();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const isAuthenticated = Boolean(localStorage.getItem("userUUID"));
    if (isAuthenticated) {
      navigate("/home");
    }
  }, [navigate]);

  if (loading) return <Loading />;
  if (error)
    return (
      <div className="text-center text-red-500">Error: {error.message}</div>
    );

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-900 to-indigo-900 text-white overflow-x-hidden">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 bg-black bg-opacity-50 backdrop-blur-md p-4 z-50">
        <div className="container mx-auto flex justify-between items-center">
          <motion.h1
            className="text-2xl md:text-3xl font-bold"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {data?.nameApp || "Nuestra App"}
          </motion.h1>
          <nav className="hidden md:flex space-x-4">
            <NavLink to="/create-account">Registrarse</NavLink>
            <NavLink to="/about">Acerca de</NavLink>
          </nav>
          <button
            className="md:hidden text-white"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </header>

      {/* Mobile Menu */}
      <motion.div
        className={`fixed inset-0 bg-black bg-opacity-90 z-40 ${
          isMenuOpen ? "flex" : "hidden"
        } flex-col items-center justify-center space-y-4`}
        initial={{ opacity: 0 }}
        animate={{ opacity: isMenuOpen ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <h3 className="text-xl font-semibold mb-4">Otras Opciones</h3>
        <NavLink
          className="text-white text-lg"
          to="/create-account"
          onClick={() => setIsMenuOpen(false)}
        >
          Registrarse
        </NavLink>
        <NavLink
          className="text-white text-lg"
          to="/about"
          onClick={() => setIsMenuOpen(false)}
        >
          Acerca de
        </NavLink>

        <div className="p-4 rounded-lg shadow-md text-white flex flex-col items-center justify-center space-y-4">
          <h3 className="text-xl font-semibold mb-4">
            Nuestras redes sociales
          </h3>
          <div className="flex flex-col items-center space-y-2">
            <a
              href={data?.social?.facebook || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex items-center justify-center w-48 px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105 space-x-2">
                <FaFacebook className="text-2xl" />
                <span>Facebook</span>
              </button>
            </a>
            <a
              href={data?.social?.instagram || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex items-center justify-center w-48 px-4 py-2 bg-pink-600 hover:bg-pink-700 text-white font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105 space-x-2">
                <FaInstagram className="text-2xl" />
                <span>Instagram</span>
              </button>
            </a>
            <a
              href={data?.social?.youtube || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex items-center justify-center w-48 px-4 py-2 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105 space-x-2">
                <IoLogoYoutube className="text-2xl" />
                <span>YouTube</span>
              </button>
            </a>
            <a
              href={data?.social?.spotify || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex items-center justify-center w-48 px-4 py-2 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105 space-x-2">
                <FaSpotify className="text-2xl" />
                <span>Spotify</span>
              </button>
            </a>
            <a
              href={data?.social?.tiktok || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex items-center justify-center w-48 px-4 py-2 bg-teal-600 hover:bg-teal-700 text-white font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105 space-x-2">
                <FaTiktok className="text-2xl" />
                <span>TikTok</span>
              </button>
            </a>
            <a
              href={data?.social?.blogs || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex items-center justify-center w-48 px-4 py-2 bg-orange-600 hover:bg-orange-700 text-white font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105 space-x-2">
                <FaBlog className="text-2xl" />
                <span>Blogs</span>
              </button>
            </a>
          </div>
        </div>
      </motion.div>

      {/* Hero Section */}
      <motion.section
        className="pt-24 pb-12 px-4 text-center bg-cover bg-center min-h-screen flex items-center justify-center"
        style={{
          backgroundImage:
            'url("https://www.semana.com/resizer/AfpbiiobE_ZuLLKWy9ZMnhkWhjA=/arc-anglerfish-arc2-prod-semana/public/2CQBVNCQBFA37I7A3ROSV6OBXY.jpg")',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="container mx-auto bg-black bg-opacity-60 p-8 rounded-xl">
          <motion.h2
            className="text-4xl md:text-5xl font-bold mb-6"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Bienvenido a {data?.nameApp || "Nuestra App"}
          </motion.h2>
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            <Link
              to="/login"
              className="px-6 py-3 bg-green-600 hover:bg-green-700 text-white text-lg font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105 shadow-lg inline-block"
            >
              Iniciar Sesión
            </Link>
          </motion.div>
          <p className="text-gray-500 mt-4 text-sm text-center">
            <NavLink
              to="/create-account"
              className="text-blue-500 hover:text-blue-700 font-medium"
            >
              Regístrate
            </NavLink>
          </p>
        </div>
      </motion.section>

      {/* Features Section */}
      <section className="py-16 bg-gray-800">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-12 text-center text-white">
            Nuestras Características
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-700 p-6 rounded-lg shadow-md text-white flex flex-col items-center">
              <FaRocket className="text-4xl mb-4 text-blue-500" />
              <h3 className="text-xl font-semibold mb-2">Plataforma Fácil de Usar</h3>
              <p>
              Nuestra aplicación está diseñada para ser intuitiva y fácil de usar, permitiéndote encontrar y reservar servicios para tu mascota en pocos clics.
              </p>
            </div>
            <div className="bg-gray-700 p-6 rounded-lg shadow-md text-white flex flex-col items-center">
              <FaShieldAlt className="text-4xl mb-4 text-green-500" />
              <h3 className="text-xl font-semibold mb-2">Productos de Alta Calidad</h3>
              <p>En nuestra plataforma, solo encontrarás productos de las mejores marcas para el bienestar de tu mascota.</p>
            </div>
            <div className="bg-gray-700 p-6 rounded-lg shadow-md text-white flex flex-col items-center">
              <FaUsers className="text-4xl mb-4 text-purple-500" />
              <h3 className="text-xl font-semibold mb-2">Conéctate con Amantes de Mascotas</h3>
              <p>Únete a una comunidad global para compartir experiencias y consejos.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-16 bg-indigo-900">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8 text-white">Beneficios</h2>
          <p className="text-xl text-gray-200 mb-12">
            Aprovecha todas las ventajas que ofrecemos para mejorar tu
            experiencia.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-indigo-800 p-6 rounded-lg shadow-md text-white flex flex-col items-center">
              <FaCheckCircle className="text-4xl mb-4 text-green-400" />
              <h3 className="text-xl font-semibold mb-2">Beneficio 1</h3>
              <p>
                Disfruta de funcionalidades exclusivas que mejoran tu
                productividad.
              </p>
            </div>
            <div className="bg-indigo-800 p-6 rounded-lg shadow-md text-white flex flex-col items-center">
              <FaStar className="text-4xl mb-4 text-yellow-400" />
              <h3 className="text-xl font-semibold mb-2">Beneficio 2</h3>
              <p>
                Obtén una experiencia de usuario premiada y altamente
                calificada.
              </p>
            </div>
            <div className="bg-indigo-800 p-6 rounded-lg shadow-md text-white flex flex-col items-center">
              <FaThumbsUp className="text-4xl mb-4 text-blue-400" />
              <h3 className="text-xl font-semibold mb-2">Beneficio 3</h3>
              <p>
                Recibe soporte excepcional y confiable cuando más lo necesites.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* New Image Section */}
      <section className="py-16 bg-gradient-to-r from-purple-900 to-indigo-900">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8 text-center text-indigo-600 bg-gray-100 p-4 rounded-lg shadow-md">
            ¡La felicidad se mide en colas que se mueven y patitas que brincan!
          </h2>

          <motion.img
            src="https://www.universodelasaludanimal.com/wp-content/uploads/sites/61/2021/07/Cacho-e-gato-juntos-no-chao-posando-pra-foto_3.jpg"
            alt="Imagen representativa de la app"
            className="rounded-xl shadow-2xl mx-auto max-w-full h-auto"
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          />
        </div>
        {/* <a href="/inicio" className="flex items-center justify-center px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white font-bold rounded-lg shadow-lg transition duration-300 ease-in-out transform hover:scale-105">
          <span className="text-lg">¡Empezar!</span>
          <FcNext className="text-2xl ml-2" />
        </a> */}
      </section>

      <footer className="bg-black bg-opacity-80 text-center py-6">
        <div className="container mx-auto px-4">
          <p className="text-gray-300 text-sm">
            &copy; {new Date().getFullYear()} {data?.nameApp || "Nuestra App"}.
            Todos los derechos reservados.
          </p>
          <div className="mt-4">
            <a
              href="/privacy-policy"
              className="text-gray-400 hover:text-white mx-2"
            >
              Política de Privacidad
            </a>
            <a
              href="/terms-of-service"
              className="text-gray-400 hover:text-white mx-2"
            >
              Términos de Servicio
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

const NavLink = ({ to, children, onClick }) => (
  <Link
    to={to}
    className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
    onClick={onClick}
  >
    {children}
  </Link>
);

const FeatureCard = ({ title, index }) => (
  <motion.div
    className="bg-gray-700 p-6 rounded-xl shadow-lg hover:shadow-2xl transition-shadow duration-300"
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: index * 0.1 }}
    viewport={{ once: true }}
  >
    <h3 className="text-2xl font-semibold mb-4">{title}</h3>
    <p>
      Descripción detallada de la funcionalidad {index + 1} de la aplicación.
    </p>
  </motion.div>
);

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export default Landing;
