import React from 'react';
import { FaFileContract } from 'react-icons/fa';

const TermsOfService = () => {
  return (
    <div className="p-8 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <FaFileContract className="text-teal-500 text-3xl mr-4" />
        <h1 className="text-3xl font-bold">Términos de Servicio</h1>
      </div>
      <p className="text-gray-700 mb-4">Fecha de vigencia: [Fecha]</p>
      <p className="text-gray-700 mb-4">
        Bienvenido a [Nombre de la Aplicación]. Al utilizar nuestros servicios, aceptas cumplir con los siguientes términos y condiciones. Por favor, léelos detenidamente.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Uso de la Aplicación</h2>
      <p className="text-gray-700 mb-4">
        Nuestra aplicación está destinada a proporcionar [Descripción del Servicio]. Al utilizar nuestra aplicación, te comprometes a usarla de manera legal y responsable.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Cuenta de Usuario</h2>
      <p className="text-gray-700 mb-4">
        Eres responsable de mantener la confidencialidad de tu cuenta y contraseña, y aceptas notificar de inmediato cualquier uso no autorizado de tu cuenta.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Contenido</h2>
      <p className="text-gray-700 mb-4">
        Todo el contenido proporcionado en nuestra aplicación, incluyendo textos, imágenes y gráficos, es propiedad de [Nombre de la Aplicación] o sus licenciantes. No puedes reproducir, distribuir o utilizar este contenido sin autorización.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Limitación de Responsabilidad</h2>
      <p className="text-gray-700 mb-4">
        [Nombre de la Aplicación] no se hace responsable de cualquier daño o pérdida resultante del uso de nuestra aplicación. Utilizas nuestros servicios bajo tu propio riesgo.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Modificaciones de los Términos</h2>
      <p className="text-gray-700 mb-4">
        Podemos modificar estos términos ocasionalmente. Te notificaremos cualquier cambio publicando los nuevos términos en esta página.
      </p>
      <p className="text-gray-700">
        Si tienes alguna pregunta sobre estos términos, por favor, contáctanos a través de [Correo Electrónico].
      </p>
    </div>
  );
};

export default TermsOfService;
