import React from 'react';
import { FaCheckCircle } from 'react-icons/fa'; // Importa el ícono de verificación
import useUserData from '../hooks/useUserData'; // Importa el hook correctamente
import BottomNavigation from '../components/BottomNavigation';
import Loading from '../utils/Loading'; // Asegúrate de que la ruta es correcta

export default function UserProfile() {
  const { name, email, phone, location, loading, error, photoUrl, isInClub } = useUserData();
  const userPlaceholder = 'https://cdn-icons-png.flaticon.com/512/3607/3607444.png'; // URL de la imagen de placeholder

  if (loading) return <Loading/>;
  if (error) return <div className="text-center p-6 text-red-600">{error}</div>;

  return (
    <div className="relative min-h-screen flex flex-col">
      <div className="absolute inset-0 overflow-hidden">
        <img
          src="https://realestatemarket.com.mx/images/2020/12-Diciembre/0412/grande_Alistan_recursos_de_inversin_para_Carretera_Transpeninsular.gif"
          alt="Background"
          className="object-cover w-full h-full"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div className="relative z-10 flex-grow flex items-center justify-center py-12">
        <div className="p-6 max-w-3xl mx-auto rounded-lg shadow-md">
          <h1 className="text-3xl font-bold mb-8 text-center text-white">Perfil del Usuario</h1>
          <div className="flex flex-col items-center">
            <div className="relative w-32 h-32 mb-4">
              <img
                src={photoUrl || userPlaceholder}
                alt="User Avatar"
                className="w-full h-full object-cover rounded-full border-2 border-gray-300"
              />
              {/* Icono de verificación si el usuario está en el club */}
              {isInClub && (
                <FaCheckCircle
                  className="absolute bottom-0 right-0 text-yellow-500"
                  size={32}
                />
              )}
            </div>
            <div className="space-y-6 text-center text-white">
              <div>
                <h2 className="text-xl font-semibold">Nombre:</h2>
                <p>{name || 'No disponible'}</p>
              </div>
              <div>
                <h2 className="text-xl font-semibold">Correo Electrónico:</h2>
                <p>{email || 'No disponible'}</p>
              </div>
              <div>
                <h2 className="text-xl font-semibold">Teléfono:</h2>
                <p>{phone || 'No disponible'}</p>
              </div>
              <div>
                <h2 className="text-xl font-semibold">Ubicación:</h2>
                <p>{location || 'No disponible'}</p>
              </div>
            </div>
          </div>
        </div>
      <BottomNavigation/>

      </div>
    </div>
  );
}
