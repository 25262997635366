import React from 'react';
import BottomNavigation from '../components/BottomNavigation';
import BackButton from '../components/BackButton';

export default function RadioOnline() {
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600">
      <header className="fixed top-0 left-0 right-0 z-50 p-4 bg-gray-900 text-white shadow-md">
        <div className="container mx-auto flex items-center">
          <BackButton/>
          <h1 className="ml-2 text-red-600 text-xl font-bold sm:text-2xl">Radio Online</h1>
        </div>
      </header>

      <main className="flex-grow pt-20 p-4 flex flex-col items-center space-y-6">
        <div className="w-full max-w-md text-white text-center">
          <p className="mb-4 text-lg font-medium">
            Bienvenido a nuestra Radio Online. Escucha la mejor música y mantente informado con nuestras noticias sobre mascotas y cuidados.
          </p>
        </div>
        <div className="w-full max-w-md overflow-hidden">
          <iframe
            src="https://deportivospro-radio.web.app" // URL del radio que quieres mostrar
            width="100%"
            height="180"
            frameBorder="0"
            style={{ overflow: 'hidden' }}
            title="Radio Online"
          />
        </div>
        <div className="w-full max-w-md text-white text-center mt-4">
          <h2 className="text-xl font-semibold mb-2">Enlaces Útiles</h2>
          <ul className="space-y-2">
            <li>
              <a href="https://www.caster.fm/" className="text-blue-300 hover:text-blue-500">
                Free Shoutcast Hosting
              </a>
            </li>
            <li>
              <a href="https://www.caster.fm/" className="text-blue-300 hover:text-blue-500">
                Radio Stream Hosting
              </a>
            </li>
          </ul>
        </div>
      </main>
      <BottomNavigation/>

    </div>
  );
}
