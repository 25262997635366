import React, { useState } from 'react';
import { getDatabase, ref, push } from 'firebase/database';

const Book = ({ storeId }) => {
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verifica si los campos están llenos
    if (!name || !date || !time) {
      alert('Por favor, completa todos los campos');
      return;
    }

    // Verifica si storeId está definido
    if (!storeId) {
      alert('Error: ID de tienda no definido');
      console.error('Error: ID de tienda no definido');
      return;
    }

    // Guarda la reserva en Firebase bajo el nodo de la tienda específica
    const db = getDatabase();
    const bookingsRef = ref(db, `stores/${storeId}/bookings`);
    push(bookingsRef, {
      name,
      date,
      time,
    })
      .then(() => {
        setSuccessMessage('Reserva realizada con éxito!');
        setName('');
        setDate('');
        setTime('');
      })
      .catch((error) => {
        console.error('Error al guardar la reserva:', error);
      });
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold text-red-500">Reserva tu cita</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-300">Nombre:</label>
          <input
            type="text"
            className="w-full p-2 rounded-md bg-gray-700 text-white"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-300">Fecha:</label>
          <input
            type="date"
            className="w-full p-2 rounded-md bg-gray-700 text-white"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-300">Hora:</label>
          <input
            type="time"
            className="w-full p-2 rounded-md bg-gray-700 text-white"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
          Reservar
        </button>
      </form>
      {successMessage && <p className="text-green-400 mt-4">{successMessage}</p>}
    </div>
  );
};

export default Book;
