import React, { useState } from 'react';
import { getDatabase, ref, push } from 'firebase/database';
import { auth } from '../utils/firebase';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { motion, AnimatePresence } from 'framer-motion';
import { FaInfoCircle, FaMapMarkerAlt, FaPhone, FaPaw, FaTruck, FaUser, FaImage, FaPaperPlane } from 'react-icons/fa';
import { TiArrowBack } from 'react-icons/ti';
import BackButton from '../components/BackButton';

const steps = [
  { icon: FaInfoCircle, title: "Descripción" },
  { icon: FaMapMarkerAlt, title: "Ubicación" },
  { icon: FaPhone, title: "Contacto" },
  { icon: FaPaw, title: "Mascota" },
  { icon: FaImage, title: "Imagen" },
];


export default function EmergencyRequest() {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    description: '',
    location: '',
    phoneNumber: '',
    petName: '',
    petTransport: 'bring',
    isOwner: false,
    image: null,
  });
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleImagePick = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({ ...prev, image: file }));
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const uploadImage = async (file) => {
    const storage = getStorage();
    const imageRef = storageRef(storage, `images/${file.name}`);
    await uploadBytes(imageRef, file);
    return getDownloadURL(imageRef);
  };

  const validateForm = () => {
    if (!formData.description.trim()) {
      setError('La descripción es obligatoria');
      return false;
    }
    if (!formData.location.trim()) {
      setError('La ubicación es obligatoria');
      return false;
    }
    if (!/^\d{9,}$/.test(formData.phoneNumber)) {
      setError('Ingrese un número de teléfono válido (mínimo 9 dígitos)');
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    setLoading(true);
    setError('');
    setSuccess('');

    const user = auth.currentUser;
    if (!user) {
      setError('Debes estar logueado para solicitar ayuda.');
      setLoading(false);
      return;
    }

    const db = getDatabase();
    const emergencyRef = ref(db, 'emergencies');

    try {
      let imageUrl = '';
      if (formData.image) {
        imageUrl = await uploadImage(formData.image);
      }

      await push(emergencyRef, {
        userId: user.uid,
        ...formData,
        imageUrl,
        timestamp: new Date().toISOString()
      });
      setSuccess('Tu solicitud de emergencia ha sido enviada con éxito.');
      resetForm();
    } catch (error) {
      setError('Ocurrió un error al enviar la solicitud. Inténtalo de nuevo.');
      console.error('Error al enviar la solicitud:', error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setFormData({
      description: '',
      location: '',
      phoneNumber: '',
      petName: '',
      petTransport: 'bring',
      isOwner: false,
      image: null,
    });
    setPreviewUrl('');
    setCurrentStep(0);
  };

  const nextStep = () => setCurrentStep(prev => Math.min(prev + 1, steps.length - 1));
  const prevStep = () => setCurrentStep(prev => Math.max(prev - 1, 0));

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <label htmlFor="description" className="block text-sm font-semibold text-gray-700 mb-2">
              <FaInfoCircle className="inline mr-2" />
              Descripción de la emergencia
            </label>
            <textarea
              id="description"
              name="description"
              className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Describe la emergencia"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </motion.div>
        );
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <label htmlFor="location" className="block text-sm font-semibold text-gray-700 mb-2">
              <FaMapMarkerAlt className="inline mr-2" />
              Ubicación
            </label>
            <input
              type="text"
              id="location"
              name="location"
              className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Tu ubicación actual"
              value={formData.location}
              onChange={handleChange}
              required
            />
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <label htmlFor="phoneNumber" className="block text-sm font-semibold text-gray-700 mb-2">
              <FaPhone className="inline mr-2" />
              Número de Teléfono
            </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Tu número de teléfono"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <label htmlFor="petName" className="block text-sm font-semibold text-gray-700 mb-2">
              <FaPaw className="inline mr-2" />
              Nombre de la mascota
            </label>
            <input
              type="text"
              id="petName"
              name="petName"
              className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Nombre de la mascota"
              value={formData.petName}
              onChange={handleChange}
            />
            <div className="mt-4">
              <label htmlFor="petTransport" className="block text-sm font-semibold text-gray-700 mb-2">
                <FaTruck className="inline mr-2" />
                Necesito
              </label>
              <select
                id="petTransport"
                name="petTransport"
                className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={formData.petTransport}
                onChange={handleChange}
              >
                <option value="bring">Llevar al animal</option>
                <option value="pickUp">Que lleven al animal</option>
              </select>
            </div>
            <div className="flex items-center mt-4">
              <input
                type="checkbox"
                id="isOwner"
                name="isOwner"
                className="mr-2"
                checked={formData.isOwner}
                onChange={handleChange}
              />
              <label htmlFor="isOwner" className="text-sm font-semibold text-gray-700">
                <FaUser className="inline mr-2" />
                Soy el dueño de la mascota
              </label>
            </div>
          </motion.div>
        );
      case 4:
        return (
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
          >
            <label htmlFor="image" className="block text-sm font-semibold text-gray-700 mb-2">
              <FaImage className="inline mr-2" />
              Adjuntar imagen (opcional)
            </label>
            <input
              type="file"
              id="image"
              name="image"
              className="w-full border border-gray-300 rounded-lg shadow-sm"
              accept="image/*"
              onChange={handleImagePick}
            />
            {previewUrl && (
              <motion.div 
                className="mt-4"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
              >
                <img
                  src={previewUrl}
                  alt="Previsualización"
                  className="w-full max-w-sm h-auto border border-gray-300 rounded-lg"
                />
              </motion.div>
            )}
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600">
      <header className="fixed top-0 left-0 right-0 z-50 p-4 bg-gray-900 text-white shadow-md">
        <div className="container mx-auto flex items-center">
        <BackButton/>
          <h1 className="ml-2 text-red-600 text-xl font-bold sm:text-2xl">Solicitud de Emergencia</h1>
        </div>
      </header>

      <main className="flex-grow pt-20 p-4">
        <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-6">
          <div className="flex justify-between mb-8">
            {steps.map((step, index) => (
              <div key={index} className={`flex flex-col items-center ${index <= currentStep ? 'text-blue-600' : 'text-gray-400'}`}>
                <step.icon className="text-2xl mb-1" />
                <span className="text-xs">{step.title}</span>
              </div>
            ))}
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            {error && <div className="text-red-600 font-medium mb-4">{error}</div>}
            {success && <div className="text-green-600 font-medium mb-4">{success}</div>}
            
            <AnimatePresence mode="wait">
              {renderStep()}
            </AnimatePresence>

            <div className="flex justify-between mt-8">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="button"
                onClick={prevStep}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg shadow"
                disabled={currentStep === 0}
              >
                Anterior
              </motion.button>
              {currentStep < steps.length - 1 ? (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="button"
                  onClick={nextStep}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow"
                >
                  Siguiente
                </motion.button>
              ) : (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="submit"
                  className="px-4 py-2 bg-green-600 text-white rounded-lg shadow flex items-center"
                  disabled={loading}
                >
                  <FaPaperPlane className="mr-2" />
                  {loading ? 'Enviando...' : 'Enviar Solicitud'}
                </motion.button>
              )}
            </div>
          </form>
        </div>
      </main>
    </div>
  );
}