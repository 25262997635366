// src/utils/Loading.jsx

import React from 'react';

const Loading = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-800 text-white">
      <div className="text-center">
        <svg className="animate-spin h-10 w-10 text-white mx-auto mb-4" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291a7.958 7.958 0 01-1.733-1.733L2.01 17.01A9.985 9.985 0 004 20.984v-3.693zM12 24a12 12 0 000-24v4a8 8 0 018 8h4c0-6.627-5.373-12-12-12v4a8 8 0 01-8 8H0c0 6.627 5.373 12 12 12z"></path>
        </svg>
        <p className="text-xl">Cargando...</p>
      </div>
    </div>
  );
}

export default Loading;
