import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { auth } from '../utils/firebase'; // Ajusta la ruta según tu estructura

export default function HomeClub() {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const [events, setEvents] = useState([]);
  const [activeSection, setActiveSection] = useState('home'); // Sección activa

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          window.location.href = '/Club';
          return;
        }

        const db = getDatabase();
        const userRef = ref(db, `club/${user.uid}`);
        const snapshot = await get(userRef);

        if (!snapshot.exists()) {
          window.location.href = '/Club';
          return;
        }

        setUserData(snapshot.val());

        const eventsRef = ref(db, 'events');
        const eventsSnapshot = await get(eventsRef);
        if (eventsSnapshot.exists()) {
          const eventsData = eventsSnapshot.val();
          setEvents(Object.values(eventsData)); // Convierte el objeto a un array
        }
      } catch (err) {
        setError('Error al cargar la información del club. Por favor, intenta nuevamente.');
        console.error('Error al cargar la información del club:', err);
      }
    };

    fetchData();
  }, []);

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const shareEventOnWhatsApp = (event) => {
    const text = encodeURIComponent(`¡No te pierdas este evento! ${event.name}: ${event.description}. Más detalles aquí: ${window.location.href}`);
    window.open(`https://wa.me/?text=${text}`, '_blank');
  };

  return (
    <div className="flex flex-col min-h-screen bg-cover bg-center" style={{ backgroundImage: 'url("https://www.panchocavero.com/assets/img/blog/3/1.jpg")' }}>
      <header className="fixed top-0 left-0 w-full bg-gray-900 bg-opacity-70 text-white flex justify-around items-center p-4 z-10">
        <img
          src="https://www.businessinsider.es/sites/navi.axelspringer.es/public/media/image/2021/02/gatos-vs-perros-2238147.jpg"
          alt="Club Banner"
          className="w-full h-60 object-cover absolute top-0 left-0 z-0"
        />
        <nav className="relative flex justify-around items-center w-full">
          <a href="#home" onClick={() => handleSectionChange('home')} className={`hover:text-yellow-300 ${activeSection === 'home' ? 'text-yellow-300' : ''}`}>Inicio</a>
          <a href="#events" onClick={() => handleSectionChange('events')} className={`hover:text-yellow-300 ${activeSection === 'events' ? 'text-yellow-300' : ''}`}>Eventos</a>
          <a href="#about" onClick={() => handleSectionChange('about')} className={`hover:text-yellow-300 ${activeSection === 'about' ? 'text-yellow-300' : ''}`}>Sobre Nosotros</a>
          <a href="#contact" onClick={() => handleSectionChange('contact')} className={`hover:text-yellow-300 ${activeSection === 'contact' ? 'text-yellow-300' : ''}`}>Contacto</a>
        </nav>
      </header>
      <div className="pt-64 container mx-auto p-4 flex flex-col items-center space-y-6">
        {error && <div className="text-red-600 mb-4">{error}</div>}
        {userData ? (
          <>
            {activeSection === 'home' && (
              <div className="w-full max-w-3xl text-white text-center bg-gray-900 bg-opacity-70 p-6 rounded-lg shadow-md">
                <h2 className="text-3xl font-semibold mb-4">Hola, {userData.name}!</h2>
                <p className="mb-4 text-lg font-medium">
                  Gracias por ser parte de nuestro club. Aquí tienes algunas de las últimas actualizaciones y eventos.
                </p>
              </div>
            )}
            {activeSection === 'events' && (
              <div id="events" className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">Eventos Próximos</h3>
                {events.length ? (
                  <ul className="space-y-4">
                    {events.map((event, index) => (
                      <li key={index} className="border-b border-gray-300 pb-2 mb-2">
                        <img src={event.imgUrl} alt={event.name} className="w-full h-40 object-cover mb-2" />
                        <h4 className="text-lg font-medium">{event.name}</h4>
                        <p className="text-blue-400">{event.location}</p>
                        <p className="text-gray-700">{event.description}</p>
                        <p className="text-blue-600">{event.date}</p>
                        <div className="flex space-x-4 mt-2">
                          <button
                            onClick={() => shareEventOnWhatsApp(event)}
                            className="text-green-500 hover:text-green-700"
                            title="Compartir en WhatsApp"
                          >
                            <i className="fab fa-whatsapp fa-lg"></i>
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-700">No hay eventos próximos en este momento.</p>
                )}
              </div>
            )}
            {activeSection === 'about' && (
              <div id="about" className="w-full max-w-3xl text-white text-center bg-gray-900 bg-opacity-70 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">Sobre Nosotros</h3>
                <p>En esta sección, puedes encontrar información sobre nuestro club, nuestra misión y visión, y lo que hacemos para mejorar la vida de las mascotas y sus dueños.</p>
              </div>
            )}
            {activeSection === 'contact' && (
              <div id="contact" className="w-full max-w-3xl text-white text-center bg-gray-900 bg-opacity-70 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-2">Contacto</h3>
                <p>Para ponerte en contacto con nosotros, por favor envía un correo a <a href="mailto:contacto@clubdemascotas.com" className="text-yellow-300">contacto@clubdemascotas.com</a> o llámanos al 123-456-7890.</p>
              </div>
            )}
          </>
        ) : (
          <div className="text-white text-center">
            <p>Cargando información del usuario...</p>
          </div>
        )}
      </div>
    </div>
  );
}
