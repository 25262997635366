import React, { useEffect } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../utils/firebase";
import { Link, useNavigate } from "react-router-dom";
import {
  FaSearch,
  FaStore,
  FaBox,
  FaExclamationTriangle,
  FaStar,
  FaTools,
  FaLifeRing,
} from "react-icons/fa";
import { FaRadio } from "react-icons/fa6";
import BottomNavigation from "../components/BottomNavigation";
import useUserData from "../hooks/useUserData";
import Loading from "../utils/Loading";
import { motion } from "framer-motion";
import Carousel from "../components/home/Carousel";

export default function HomeU() {
  const navigate = useNavigate();
  const { name, loading, error } = useUserData();

  useEffect(() => {
    if (loading) return;

    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (!currentUser) {
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [loading, navigate]);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error al cerrar sesión:", error);
      });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p className="text-gray-400">Error: {error}</p>;
  }

  const firstName = name.split(" ")[0];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col min-h-screen bg-gradient-to-r from-blue-500 via-purple-500 to-red-500 bg-cover bg-no-repeat bg-center"
      style={{
        backgroundImage:
          'url("https://www.blogdelfotografo.com/wp-content/uploads/2020/11/perro-exterior.webp")',
      }}
    >
      {/* Header */}
      <motion.header
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
        className="fixed top-0 left-0 right-0 z-50 p-4 bg-gray-900 text-white shadow-md"
      >
        <div className="container mx-auto flex justify-between items-center">
          <Link to="/profile">
            <h1 className="text-xl font-bold sm:text-2xl">{firstName}</h1>
          </Link>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={handleSignOut}
            className="px-3 py-2 bg-red-600 rounded-lg shadow-md hover:bg-red-700 transition-colors text-sm sm:text-base"
          >
            Cerrar sesión
          </motion.button>
        </div>
      </motion.header>

      {/* Main Content */}
        <Carousel />
      <main className="flex-grow p-4">
        {/* Quick Actions */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="grid grid-cols-2 gap-4 mb-12"
        >
          {[
            { to: "/search", icon: FaSearch, text: "Buscar" },
            { to: "/stores", icon: FaStore, text: "Tiendas" },
            { to: "/all-products", icon: FaBox, text: "Productos" },
            { to: "/emergency", icon: FaExclamationTriangle, text: "Emergencia" },
            { to: "/learn", icon: FaStar, text: "Cursos" },
            { to: "/services", icon: FaTools, text: "Servicios" },
            { to: "/radio-online", icon: FaRadio, text: "Radio Online" },
            { to: "/support", icon: FaLifeRing, text: "Ayuda" },
          ].map((item, index) => (
            <QuickActionLink key={item.to} {...item} index={index} />
          ))}
        </motion.div>

        {/* Recent Activity */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="bg-gray-900 rounded-xl p-6 mb-12"
        >
          <h3 className="text-xl text-white font-semibold mb-6">
            Actividad Reciente
          </h3>
          <motion.ul
            initial="hidden"
            animate="visible"
            variants={containerVariants}
            className="space-y-4"
          >
            <motion.li variants={itemVariants}>
              <Link to="/history-emergency" className="block">
                <motion.div
                  whileHover={{ scale: 1.03 }}
                  className="bg-gray-800 p-4 rounded-lg text-blue-400 hover:bg-gray-700 transition-colors"
                >
                  Solicitudes de emergencia
                </motion.div>
              </Link>
            </motion.li>
          </motion.ul>
        </motion.div>
      </main>

      {/* Footer */}
      <motion.footer
        initial={{ y: 50 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-gray-900 text-white py-3 sm:py-4"
      >
        <BottomNavigation />
      </motion.footer>
    </motion.div>
  );
}

const QuickActionLink = ({ to, icon: Icon, text, index }) => (
  <motion.div
    variants={itemVariants}
    initial="hidden"
    animate="visible"
    custom={index}
  >
    <Link
      to={to}
      className="bg-gray-800 p-4 rounded-xl text-center text-white flex flex-col items-center"
    >
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <Icon className="text-4xl text-red-500 mb-2" />
      </motion.div>
      <p>{text}</p>
    </Link>
  </motion.div>
);

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: custom * 0.1,
      type: "spring",
      stiffness: 100
    }
  })
};