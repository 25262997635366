import { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { auth } from '../utils/firebase'; // Ajusta la ruta si es necesario

const useUserData = () => {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phone: '',
    location: '',
    isInClub: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const db = getDatabase();
        const userRef = ref(db, `users/${user.uid}`);
        
        const dataUnsubscribe = onValue(userRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            setUserData({
              name: data.username || '',
              email: data.email || '',
              phone: data.phone || '',
              location: data.location || '',
              isInClub: data.isInClub || ''
            });
          } else {
            setUserData({
              name: '',
              email: '',
              phone: '',
              location: '',
              isInClub: ''
            });
          }
          setLoading(false);
        }, (error) => {
          setError(error.message);
          setLoading(false);
        });

        // Cleanup
        return () => {
          dataUnsubscribe();
          unsubscribe();
        };
      } else {
        setLoading(false);
      }
    });

    // Cleanup
    return () => unsubscribe();

  }, []);

  return { ...userData, loading, error };
};

export default useUserData;
