import React from 'react';
import { FaLock } from 'react-icons/fa';

const PrivacyPolicy = () => {
  return (
    <div className="p-8 max-w-4xl mx-auto">
      <div className="flex items-center mb-6">
        <FaLock className="text-teal-500 text-3xl mr-4" />
        <h1 className="text-3xl font-bold">Política de Privacidad</h1>
      </div>
      <p className="text-gray-700 mb-4">Fecha de vigencia: [Fecha]</p>
      <p className="text-gray-700 mb-4">
        En [Nombre de la Aplicación], valoramos tu privacidad y estamos comprometidos con la protección de tus datos personales. Esta política de privacidad describe cómo recopilamos, utilizamos y compartimos tu información cuando utilizas nuestra aplicación.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Recopilación de Información</h2>
      <p className="text-gray-700 mb-4">
        Recopilamos información personal que nos proporcionas directamente, como tu nombre, dirección de correo electrónico, y cualquier otra información que decides compartir con nosotros.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Uso de la Información</h2>
      <p className="text-gray-700 mb-4">
        Utilizamos tu información para proporcionarte nuestros servicios, mejorar la experiencia del usuario, y comunicarnos contigo sobre actualizaciones o promociones.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Compartición de la Información</h2>
      <p className="text-gray-700 mb-4">
        No compartimos tu información personal con terceros, excepto cuando es necesario para cumplir con la ley, proteger nuestros derechos, o proporcionar los servicios que has solicitado.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Seguridad</h2>
      <p className="text-gray-700 mb-4">
        Implementamos medidas de seguridad para proteger tu información personal contra el acceso no autorizado, alteración, divulgación o destrucción.
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Derechos del Usuario</h2>
      <p className="text-gray-700 mb-4">
        Tienes derecho a acceder, corregir o eliminar tu información personal. Si deseas ejercer estos derechos, por favor, contáctanos a través de [Correo Electrónico].
      </p>
      <h2 className="text-xl font-semibold mt-6 mb-2">Cambios en esta Política</h2>
      <p className="text-gray-700 mb-4">
        Podemos actualizar nuestra política de privacidad ocasionalmente. Te notificaremos cualquier cambio publicando la nueva política en esta página.
      </p>
      <p className="text-gray-700">
        Si tienes alguna pregunta o inquietud sobre nuestra política de privacidad, no dudes en contactarnos.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
