import React, { useState, useEffect } from 'react';
import { database } from '../utils/firebase'; // Importa tu configuración de Firebase
import { ref, get, child } from 'firebase/database';
import { FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import BackButton from '../components/BackButton';

export default function ServicesView() {
  const [searchTerm, setSearchTerm] = useState('');
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const navigate = useNavigate(); // Hook para navegación

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const dbRef = ref(database); // Referencia a la raíz de la base de datos
        const snapshot = await get(child(dbRef, 'stores')); // Obtén los datos de 'stores'

        if (snapshot.exists()) {
          const storesData = snapshot.val();
          const servicesList = [];

          Object.keys(storesData).forEach(storeId => {
            const store = storesData[storeId];
            if (store.service) {
              Object.keys(store.service).forEach(serviceId => {
                const service = store.service[serviceId];
                servicesList.push({
                  id: serviceId,
                  ...service,
                  storeId, // Agrega storeId a cada servicio
                  storeName: store.name || '',
                });
              });
            }
          });

          setServices(servicesList);
        } else {
          console.log('No data available in stores.');
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    setFilteredServices(
      services.filter(service =>
        service.name && service.name.toLowerCase().includes(searchTerm.toLowerCase()) // Verifica que `service.name` no sea `undefined`
      )
    );
  }, [searchTerm, services]);

  const handleServiceClick = (storeId, serviceId) => {
    navigate(`/store/${storeId}/service/${serviceId}`); // Navega a la ruta del servicio
  };
  
  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-blue-500 via-purple-500 to-red-500 bg-cover bg-no-repeat bg-center"
    style={{
      backgroundImage:
        'url("https://www.blogdelfotografo.com/wp-content/uploads/2020/11/perro-exterior.webp")',
    }}
    >
       <header className="fixed top-0 left-0 right-0 z-50 p-4 bg-gray-900 text-white shadow-md">
        <div className="container mx-auto flex items-center">
          <BackButton/>
          <h1 className="ml-2 text-red-600 text-xl font-bold sm:text-2xl">Servicios</h1>
        </div>
      </header>
      
      {/* Search Bar */}
      <div className="mb-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Buscar servicios..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 pl-10 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
        </div>
      </div>

      {/* Services List */}
      <div className="space-y-4">
        {filteredServices.length > 0 ? (
          filteredServices.map(service => (
            <div
              key={service.id}
              className="bg-white p-4 rounded-lg shadow-md flex items-center cursor-pointer"
              onClick={() => handleServiceClick(service.storeId, service.id)} // Maneja el clic en el servicio
            >
              {service.imgUrl && (
                <img src={service.imgUrl} alt={service.name} className="w-24 h-24 object-cover rounded-lg mr-4" />
              )}
              <div>
                <h2 className="text-lg font-semibold">{service.name || 'Nombre del servicio no disponible'}</h2>
                <p className="text-gray-500">Precio: ${service.price || 'Precio no disponible'}</p>
                <p className="text-gray-500">Tienda: {service.storeName || 'Nombre de tienda no disponible'}</p>
              </div>
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center min-h-scree">
  <div className="text-center bg-gray-900 p-6 rounded-md shadow-lg">
    <p className="text-white text-2xl font-semibold">
      No se encontraron servicios.
    </p>
  </div>
</div>

        )}
      </div>
    </div>
  );
}
