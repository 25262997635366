import React from 'react';
import { FaStore, FaMapMarkerAlt, FaPhoneAlt, FaInfoCircle, FaClock } from 'react-icons/fa';
import BottomNavigation from '../components/BottomNavigation';
import useStorage from '../hooks/userStorage';

export const Settings = () => {
  const { stores, loading, error } = useStorage();

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-red-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-black text-white">
        <div className="text-center">
          <FaInfoCircle className="text-red-500 text-5xl mb-4 mx-auto" />
          <p className="text-xl">Error al cargar datos: {error.message}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
      {/* Header */}
      <header className="p-6 bg-gray-900 shadow-lg">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex items-center">
            <img  src={stores[0].logoUrl}  alt="Logo" className="h-10 mr-3" />
            <h1 className="text-3xl font-bold text-red-500">Mi App</h1>
          </div>
          <button className="text-white hover:text-red-500 transition-colors">
            <FaInfoCircle className="text-2xl" />
          </button>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow p-6">
        <div className="container mx-auto">
          <div className="bg-gray-800 rounded-xl p-6 shadow-xl">
            <h2 className="text-2xl font-semibold mb-6 text-red-500">Datos de la tienda</h2>
            {stores && stores.length > 0 ? (
              <div className="space-y-6">
                <div className="flex items-center justify-center mb-8">
                  <img 
                    src={stores[0].logoUrl} 
                    alt="Logo de la Tienda" 
                    className="h-24 rounded-full border-4 border-red-500 shadow-lg"
                  />
                </div>
                <StoreInfoItem icon={FaStore} label="Nombre" value={stores[0].name} />
                <StoreInfoItem icon={FaMapMarkerAlt} label="Ubicación" value={stores[0].location} />
                <StoreInfoItem icon={FaPhoneAlt} label="Contacto" value={stores[0].contact} />
                <StoreInfoItem icon={FaInfoCircle} label="Descripción" value={stores[0].description} />
                <StoreInfoItem icon={FaClock} label="Horas" value={stores[0].hours} />
              </div>
            ) : (
              <p className="text-center text-gray-400">No hay datos disponibles</p>
            )}
          </div>
        </div>
      </main>

      {/* Bottom Navigation */}
      {/* <BottomNavigation /> */}
    </div>
  );
}

const StoreInfoItem = ({ icon: Icon, label, value }) => (
  <div className="flex items-center bg-gray-700 p-4 rounded-lg shadow-md">
    <Icon className="text-red-500 text-2xl mr-4" />
    <div>
      <p className="text-sm text-gray-400">{label}</p>
      <p className="text-lg">{value}</p>
    </div>
  </div>
);

export default Settings;