import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { database } from '../../utils/firebase';
import { ref, onValue } from 'firebase/database';
import { motion } from 'framer-motion';
import BackButton from '../../components/BackButton';
import { FaPlay, FaClock, FaBook, FaCertificate, FaUserGraduate } from 'react-icons/fa';

const CourseDetail = () => {
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('description');
  const { courseId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const courseRef = ref(database, `courses/${courseId}`);
    onValue(courseRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setCourse(data);
      }
      setLoading(false);
    });
  }, [courseId]);

  const handleAction = () => {
    if (course.price === 0) {
        console.log('Inscrito en el curso');
        // Aquí puedes agregar la lógica para inscribir al usuario en el curso gratuito
      } else {
        // Redirigir a la página de pago
        navigate(`/payment/${courseId}`);
      }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!course) {
    return <div className="text-center text-2xl mt-10">Curso no encontrado</div>;
  }

  const tabContent = {
    description: (
      <div>
        <h3 className="text-xl font-semibold mb-3">Descripción del curso</h3>
        <p className="text-gray-700">{course.description}</p>
      </div>
    ),
    curriculum: (
      <div>
        <h3 className="text-xl font-semibold mb-3">Programa del curso</h3>
        <ul className="space-y-2">
          {course.curriculum && course.curriculum.map((section, index) => (
            <li key={index} className="bg-gray-100 p-3 rounded">
              <h4 className="font-medium">{section.title}</h4>
              <ul className="ml-4 mt-2 list-disc">
                {section.lessons.map((lesson, lessonIndex) => (
                  <li key={lessonIndex}>{lesson}</li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    ),
    instructor: (
      <div>
        <h3 className="text-xl font-semibold mb-3">Sobre el instructor</h3>
        <div className="flex items-center mb-4">
          <img src={course.instructor.avatar} alt={course.instructor.name} className="w-16 h-16 rounded-full mr-4" />
          <div>
            <h4 className="font-medium">{course.instructor.name}</h4>
            <p className="text-gray-600">{course.instructor.title}</p>
          </div>
        </div>
        <p className="text-gray-700">{course.instructor.bio}</p>
      </div>
    ),
    reviews: (
      <div>
        <h3 className="text-xl font-semibold mb-3">Reseñas de estudiantes</h3>
        {course.reviews && course.reviews.map((review, index) => (
          <div key={index} className="mb-4 pb-4 border-b last:border-b-0">
            <div className="flex items-center mb-2">
              <div className="flex items-center">
                {[...Array(5)].map((_, i) => (
                  <svg key={i} className={`w-4 h-4 ${i < review.rating ? 'text-yellow-400' : 'text-gray-300'}`} fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                ))}
              </div>
              <span className="ml-2 font-medium">{review.author}</span>
            </div>
            <p className="text-gray-700">{review.comment}</p>
          </div>
        ))}
      </div>
    ),
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <BackButton className="mb-4 text-white" />
          <h1 className="text-4xl font-bold mb-4">{course.title}</h1>
          <p className="text-xl mb-6">{course.shortDescription}</p>
          <div className="flex flex-wrap items-center text-sm">
            <span className="mr-4 flex items-center"><FaUserGraduate className="mr-2" /> {course.studentsEnrolled} estudiantes</span>
            <span className="mr-4 flex items-center"><FaClock className="mr-2" /> {course.duration}</span>
            <span className="mr-4 flex items-center"><FaBook className="mr-2" /> {course.lessons} lecciones</span>
            <span className="flex items-center"><FaCertificate className="mr-2" /> Certificado de finalización</span>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
              <div className="border-b">
                <nav className="flex">
                  {['description', 'curriculum', 'instructor', 'reviews'].map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                      className={`px-4 py-3 font-medium ${activeTab === tab ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500'}`}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                  ))}
                </nav>
              </div>
              <div className="p-6">
                {tabContent[activeTab]}
              </div>
            </div>
          </div>

          <div className="lg:col-span-1">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden sticky top-4">
              <img src={course.imagePortada} alt={course.title} className="w-full h-48 object-cover" />
              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <span className="text-3xl font-bold text-indigo-600">
                    {course.price === 0 ? 'Gratis' : `$${course.price.toFixed(2)}`}
                  </span>
                  <div className="flex items-center">
                    <span className="text-yellow-400 font-bold mr-1">{course.rating.toFixed(1)}</span>
                    <div className="flex items-center">
                      {[...Array(5)].map((_, i) => (
                        <svg key={i} className={`w-5 h-5 ${i < Math.floor(course.rating) ? 'text-yellow-400' : 'text-gray-300'}`} fill="currentColor" viewBox="0 0 20 20">
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      ))}
                    </div>
                  </div>
                </div>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleAction}
                  className={`w-full px-6 py-3 rounded-full text-white font-semibold mb-4 flex items-center justify-center ${
                    course.price === 0 ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600'
                  } transition duration-300 ease-in-out`}
                >
                  <FaPlay className="mr-2" />
                  {course.price === 0 ? 'Inscribirse Gratis' : 'Comprar ahora'}
                </motion.button>

                <ul className="text-gray-600">
                  <li className="mb-2 flex items-center"><FaPlay className="mr-2 text-green-500" /> Acceso de por vida</li>
                  <li className="mb-2 flex items-center"><FaPlay className="mr-2 text-green-500" /> Acceso en dispositivos móviles y TV</li>
                  <li className="flex items-center"><FaPlay className="mr-2 text-green-500" /> Certificado de finalización</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetail;