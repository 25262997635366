import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDlTjAfw8VIVa_ii_mKVd_McDgmF1f7k4k",
  authDomain: "safe-animals1.firebaseapp.com",
  databaseURL: "https://safe-animals1-default-rtdb.firebaseio.com",
  projectId: "safe-animals1",
  storageBucket: "safe-animals1.appspot.com",
  messagingSenderId: "521677520920",
  appId: "1:521677520920:web:503f1ccb4f31bd22f6c36d",
  measurementId: "G-SCRNRE0L6Q"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { app, database, storage, auth }; // Agrega 'app' aquí
