// Importa las dependencias necesarias
import { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';

const useStorage = () => {
  // Define el estado para almacenar los datos
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Obtén la referencia de la base de datos de Firebase
    const db = getDatabase();
    const storesRef = ref(db, 'stores');

    // Escucha los cambios en el nodo 'stores'
    const unsubscribe = onValue(
      storesRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setStores(Object.values(data));
        } else {
          setStores([]);
        }
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

    // Limpia la suscripción cuando el componente se desmonta
    return () => unsubscribe();
  }, []);

  return { stores, loading, error };
};

export default useStorage;
