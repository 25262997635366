import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import Products from '../components/Products';
import { FaMapMarkerAlt } from 'react-icons/fa';
import Book from './Book';
import Services from '../components/Services';
import Loading from '../utils/Loading';
import { motion, AnimatePresence } from 'framer-motion';

const About = ({ description, photoUrls, location }) => {
  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`;

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="p-4"
    >
      <h2 className="text-2xl font-bold text-red-500">Estamos ubicados:</h2>
      <p className="text-white">¡Siempre serás Bienvenido!</p>
      <motion.p 
        whileHover={{ scale: 1.05 }}
        className="text-blue-400 flex items-center space-x-2"
      >
        <FaMapMarkerAlt className="text-xl" />
        <a className="underline text-blue-400" href={mapUrl} target="_blank" rel="noopener noreferrer">{location}</a>
      </motion.p>
      <h2 className="text-2xl font-bold text-red-500 mt-4">Descripción:</h2>
      <p className="text-white">{description}</p>

      <h2 className="text-2xl font-bold text-red-500 mt-4">Así nos vemos:</h2>
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
        className="mt-4 grid grid-cols-2 gap-4"
      >
        {photoUrls && photoUrls.length > 0 ? (
          photoUrls.map((url, index) => (
            <motion.img
              key={index}
              src={url}
              alt={`Store Image ${index + 1}`}
              className="w-full h-48 object-cover rounded-md shadow-lg"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            />
          ))
        ) : (
          <p className="text-gray-400">No hay imágenes disponibles.</p>
        )}
      </motion.div>
    </motion.div>
  );
};

const Contacts = ({ contact, email, facebook, instagram, youtube, tiktok, site }) => (
  <motion.div 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.5 }}
    className="p-4"
  >
    <h2 className="text-2xl font-bold text-red-500">Contactos:</h2>
    <p className="text-gray-300"><strong>Numero:</strong> {contact}</p>
    <p className="text-gray-300"><strong>WhatsApp:</strong> {contact}</p>
    <p className="text-gray-300"><strong>Correo Electrónico:</strong> {email}</p>

    <h2 className="text-2xl font-bold text-red-500 mt-4">Redes Sociales:</h2>
    {[
      { name: 'Facebook', url: facebook },
      { name: 'Instagram', url: instagram },
      { name: 'YouTube', url: youtube },
      { name: 'TikTok', url: tiktok },
      { name: 'Sitio Web', url: site },
    ].map(({ name, url }) => (
      <motion.p 
        key={name}
        className="text-gray-300"
        whileHover={{ x: 10 }}
        transition={{ type: 'spring', stiffness: 300 }}
      >
        <strong>{name}:</strong> <a className="underline text-blue-400" href={url} target="_blank" rel="noopener noreferrer">{name}</a>
      </motion.p>
    ))}
  </motion.div>
);

const StoreDetails = () => {
  const { id } = useParams();
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('about');

  useEffect(() => {
    const db = getDatabase();
    const storeRef = ref(db, `stores/${id}`);

    onValue(storeRef, (snapshot) => {
      setStore(snapshot.val());
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    setActiveTab(location.hash.slice(1) || 'about');
  }, [location.hash]);

  if (loading) return <Loading />;
  if (!store) return <div className="text-white">No se encontró la tienda.</div>;

  const renderTabContent = () => {
    switch (activeTab) {
      case 'contacts':
        return <Contacts {...store} />;
      case 'products':
        return <Products storeId={id} />;
      case 'service':
        return <Services storeID={id}/>;
      case 'book':
        return <Book storeId={id} />;
      case 'about':
      default:
        return <About description={store.description} photoUrls={store.photoUrls} location={store.location} />;
    }
  };
 
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col min-h-screen bg-black text-white"
    >
      <motion.header 
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 100 }}
        className="p-4 bg-gray-900"
      >
        <h1 className="text-2xl font-bold text-red-500">{store.name}</h1>
      </motion.header>

      <motion.div 
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: 'spring', stiffness: 200, damping: 20 }}
        className="flex justify-center items-center my-4"
      >
        <img
          src={store.logoUrl}
          alt={store.name}
          className="w-32 h-32 object-cover rounded-full shadow-lg"
        />
      </motion.div>

      <nav className="overflow-x-auto bg-gray-800">
        <ul className="flex space-x-4 p-4 whitespace-nowrap text-sm font-medium text-gray-500 border-b border-gray-700">
          {['about', 'contacts', 'products', 'book'].map((tab) => (
            <motion.li key={tab} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Link
                to={`#${tab}`}
                className={`inline-block p-4 rounded-t-lg ${activeTab === tab ? 'bg-gray-100 text-blue-600 dark:bg-gray-800 dark:text-blue-500' : 'hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </Link>
            </motion.li>
          ))}
        </ul>
      </nav>

      <AnimatePresence mode='wait'>
        <motion.div 
          key={activeTab}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          className="flex-grow"
        >
          {renderTabContent()}
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default StoreDetails;