import React, { useEffect, useState } from 'react';
import { getDatabase, ref, query, orderByChild, equalTo, onValue } from 'firebase/database';
import { auth } from '../utils/firebase'; // Ajusta la ruta según tu estructura

export default function HistoryEmergency() {
  const [emergencies, setEmergencies] = useState([]);
  const [filteredEmergencies, setFilteredEmergencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [filter, setFilter] = useState('Todos'); // Estado para el filtro seleccionado

  useEffect(() => {
    const fetchEmergencies = () => {
      const user = auth.currentUser;
      if (!user) {
        setError('Debes estar logueado para ver el historial de emergencias.');
        setLoading(false);
        return;
      }

      const db = getDatabase();
      const emergenciesRef = ref(db, 'emergencies');
      const userEmergenciesQuery = query(emergenciesRef, orderByChild('userId'), equalTo(user.uid));

      onValue(userEmergenciesQuery, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const emergenciesList = Object.keys(data).map(key => ({
            id: key,
            ...data[key]
          }));
          setEmergencies(emergenciesList);
          setFilteredEmergencies(emergenciesList); // Inicialmente muestra todas las emergencias
        } else {
          setEmergencies([]);
          setFilteredEmergencies([]);
        }
        setLoading(false);
      }, (error) => {
        setError('Error al cargar las emergencias.');
        console.error('Error al cargar las emergencias:', error);
        setLoading(false);
      });
    };

    fetchEmergencies();
  }, []);

  useEffect(() => {
    if (filter === 'Todos') {
      setFilteredEmergencies(emergencies);
    } else {
      const filtered = emergencies.filter(emergency => emergency.state === filter);
      setFilteredEmergencies(filtered);
    }
  }, [filter, emergencies]);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600">
      <header className="fixed top-0 left-0 right-0 z-50 p-4 bg-gray-900 text-white shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-red-600 text-xl font-bold sm:text-2xl">Historial</h1>
          <div className="overflow-x-auto bg-gray-800">
          <ul className="flex space-x-4 p-4 whitespace-nowrap text-sm font-medium text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
            <li
              className={`cursor-pointer ${filter === 'Pendientes' ? 'text-blue-600' : ''}`}
              onClick={() => setFilter('Pendientes')}
            >
              Pendientes
            </li>
            <li
              className={`cursor-pointer ${filter === 'Aceptados' ? 'text-blue-600' : ''}`}
              onClick={() => setFilter('Aceptados')}
            >
              Aceptados
            </li>
            <li
              className={`cursor-pointer ${filter === 'Todos' ? 'text-blue-600' : ''}`}
              onClick={() => setFilter('Todos')}
            >
              Todos
            </li>
          </ul>
        </div>
        </div>
        
      </header>

      <main className="flex-grow pt-20">
        
        <div className="container mx-auto p-4">
          {loading && <div className="text-gray-600">Cargando...</div>}
          {error && <div className="text-red-600 font-medium mb-4">{error}</div>}
          {filteredEmergencies.length === 0 && !loading && (
            <div className="text-gray-600">No tienes emergencias registradas.</div>
          )}
          {filteredEmergencies.length > 0 && (
            <ul className="space-y-4">
              {filteredEmergencies.map((emergency) => (
                <li key={emergency.id} className="p-4 bg-white rounded-lg shadow-md">
                  <h2 className="text-lg font-semibold text-gray-900">Descripción:</h2>
                  <p className="text-gray-700">{emergency.description}</p>
                  <h2 className="text-lg font-semibold text-gray-900 mt-2">Ubicación:</h2>
                  <p className="text-gray-700">{emergency.location}</p>
                  <h2 className="text-lg font-semibold text-gray-900 mt-2">Estado de la emergencia:</h2>
                  <p className="text-blue-600">{emergency.state}</p>
                  <h2 className="text-lg font-semibold text-gray-900 mt-2">Número de Teléfono:</h2>
                  <p className="text-gray-700">{emergency.phoneNumber}</p>
                  {emergency.imageUrl && (
                    <div className="mt-4">
                      <img
                        src={emergency.imageUrl}
                        alt="Imagen de emergencia"
                        className="w-full max-w-sm h-auto border border-gray-300 rounded-lg"
                      />
                    </div>
                  )}
                  <p className="text-gray-500 mt-2 text-sm">Fecha y hora: {new Date(emergency.timestamp).toLocaleString()}</p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </main>
    </div>
  );
}
