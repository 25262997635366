import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';

const Services = ({ storeId }) => {
  const [services, setServices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase();
    const servicesRef = ref(db, `stores/${storeId}/service`); // Ruta a los servicios

    console.log(`Fetching services for storeId: ${storeId}`);

    onValue(servicesRef, (snapshot) => {
      const data = snapshot.val();
      console.log('Data from Firebase:', data);

      if (data) {
        const servicesList = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setServices(servicesList);
      } else {
        setServices([]);
      }
    }, (error) => {
      console.error('Error fetching data:', error);
    });
  }, [storeId]);

  const handleServiceClick = (id) => {
    navigate(`/store/${storeId}/service/${id}`); // Navega a la ruta del servicio
  };
 
  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold text-red-500">Nuestros Servicios:</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        {services.length > 0 ? (
          services.map(service => (
            <div
              key={service.id}
              className="bg-gray-800 p-4 rounded-lg shadow-md cursor-pointer"
              onClick={() => handleServiceClick(service.id)}
            >
              <img
                src={service.imgUrl}
                alt={service.name}
                className="w-full h-32 object-cover rounded-md mb-2"
              />
              <h3 className="text-xl font-semibold text-white mb-1">{service.name}</h3>
              <p className="text-gray-300 mb-1">{service.description}</p>
              <p className="text-yellow-400 font-bold mb-1">${service.price.toLocaleString()}</p>
            </div>
          ))
        ) : (
          <p className="text-gray-400">No hay servicios disponibles.</p>
        )}
      </div>
    </div>
  );
};

export default Services;
