import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { database } from '../utils/firebase';
import { ref, get } from 'firebase/database';

export default function AdminStore() {
  const [emailOrName, setEmailOrName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const storeRef = ref(database, 'stores');
      const snapshot = await get(storeRef);

      if (snapshot.exists()) {
        const stores = snapshot.val();

        let storeFound = false;
        let storeId = '';

        Object.keys(stores).forEach((id) => {
          const store = stores[id];
          if (
            (store.email === emailOrName || store.name === emailOrName) &&
            store.password.toString() === password
          ) {
            storeFound = true;
            storeId = id; // Guarda el ID de la tienda
          }
        });

        if (storeFound) {
          localStorage.setItem('storeId', storeId); // Guarda el ID en localStorage
          setIsAuthenticated(true);
          navigate('/admin'); // Redirige al panel de administración
        } else {
          setError('Credenciales incorrectas');
        }
      } else {
        setError('No se encontraron tiendas');
      }
    } catch (err) {
      console.error('Error al verificar las credenciales:', err);
      setError('Ocurrió un error. Inténtalo de nuevo.');
    }
  };

  if (isAuthenticated) {
    return (
      <div>
        <h1>Administrar Tienda</h1>
        {/* Contenido de administración de la tienda */}
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-purple-500 via-pink-500 to-red-500">
      <div className="w-full max-w-lg p-10 space-y-8 bg-white rounded-3xl shadow-2xl">
        <h1 className="text-4xl font-extrabold text-center text-gray-900">
          Acceso de Administrador de Tienda
        </h1>
        <form onSubmit={handleSubmit}>
          {error && <div className="mb-4 text-red-600">{error}</div>}
          <div className="mb-6">
            <label htmlFor="emailOrName" className="block mb-2 text-sm font-semibold text-gray-800">
              Correo Electrónico o Nombre de Tienda
            </label>
            <input
              type="text"
              id="emailOrName"
              className="w-full px-5 py-3 text-gray-700 bg-gray-100 border border-gray-200 rounded-lg shadow-sm focus:outline-none focus:ring-4 focus:ring-purple-400"
              placeholder="correo@ejemplo.com o Nombre de Tienda"
              value={emailOrName}
              onChange={(e) => setEmailOrName(e.target.value)}
              required
            />
          </div>
          <div className="mb-8">
            <label htmlFor="password" className="block mb-2 text-sm font-semibold text-gray-800">
              Contraseña
            </label>
            <input
              type="password"
              id="password"
              className="w-full px-5 py-3 text-gray-700 bg-gray-100 border border-gray-200 rounded-lg shadow-sm focus:outline-none focus:ring-4 focus:ring-purple-400"
              placeholder="********"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="w-full px-6 py-3 text-lg font-medium text-white transition-all duration-200 bg-purple-600 rounded-full shadow-lg hover:bg-purple-700 focus:outline-none focus:ring-4 focus:ring-purple-300"
            >
              Ingresar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
