import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { database } from '../../utils/firebase';
import { ref, onValue } from 'firebase/database';
import BackButton from '../../components/BackButton';
import { motion } from 'framer-motion';

const Home = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = () => {
      const coursesRef = ref(database, 'courses');
      onValue(coursesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const coursesArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          setCourses(coursesArray);
        }
      });
    };

    fetchCourses();
  }, []);

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const filteredCourses = courses.filter((course) => {
    return (
      (selectedCategory ? course.category === selectedCategory : true) &&
      (searchTerm ? course.title.toLowerCase().includes(searchTerm.toLowerCase()) : true)
    );
  });

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const emptyStars = 5 - fullStars;
    return (
      <div className="flex items-center">
        {[...Array(fullStars)].map((_, i) => (
          <svg key={`full-${i}`} className="w-4 h-4 text-yellow-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
          </svg>
        ))}
        {[...Array(emptyStars)].map((_, i) => (
          <svg key={`empty-${i}`} className="w-4 h-4 text-gray-300" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
          </svg>
        ))}
      </div>
    );
  };

  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200">
      <header className="fixed top-0 left-0 right-0 z-50 p-4 bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-md">
        <div className="container mx-auto flex items-center">
          <BackButton />
          <h1 className="ml-2 text-red-600 text-xl font-bold sm:text-2xl">Portal de Cursos</h1>
          <button onClick={toggleMenu} className="ml-auto text-gray-800 text-2xl focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </header>

      <motion.div 
        initial={{ opacity: 0, x: '100%' }}
        animate={{ opacity: isMenuOpen ? 1 : 0, x: isMenuOpen ? 0 : '100%' }}
        transition={{ duration: 0.3 }}
        className="fixed top-0 right-0 w-64 h-full bg-white shadow-lg p-4 z-40 pt-5"
      >
        <button onClick={toggleMenu} className="text-gray-900 text-xl mb-4">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        <h2 className="text-lg font-bold mb-4">Filtros</h2>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Buscar..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full border border-gray-300 rounded-md px-4 py-2"
          />
        </div>
        <div>
          <select
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="w-full border border-gray-300 rounded-md px-4 py-2"
          >
            <option value="">Todas las Categorías</option>
            <option value="Desarrollo Web">Desarrollo Web</option>
            <option value="Backend">Backend</option>
            <option value="Diseño">Diseño</option>
            <option value="Ciencia de Datos">Ciencia de Datos</option>
          </select>
        </div>
      </motion.div>

      <div className="container mx-auto pt-24 px-4">
        <div className="flex justify-center mb-8">
          <div className="relative w-full max-w-xl">
            <input
              type="text"
              placeholder="Buscar cursos..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full px-4 py-2 pr-10 rounded-full border-2 border-blue-500 focus:outline-none focus:border-blue-600 transition duration-300"
            />
            <button onClick={toggleMenu} className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredCourses.map((course) => (
            <motion.div
              key={course.id}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleCourseClick(course.id)}
              className="bg-white rounded-xl overflow-hidden shadow-lg cursor-pointer"
            >
              <img src={course.imagePortada} alt={course.title} className="w-full h-48 object-cover" />
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-2 text-gray-800">{course.title}</h2>
                <p className="text-gray-600 mb-4 line-clamp-2">{course.description}</p>
                <div className="flex justify-between items-center">
                  {renderStars(course.rating)}
                  <span className={`font-bold ${course.price === 0 ? 'text-green-500' : 'text-blue-500'}`}>
                    {course.price === 0 ? 'Gratis' : `$${course.price.toFixed(2)}`}
                  </span>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;