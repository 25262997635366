// useFetchAppData.js
import { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { database } from '../utils/firebase'; // Asegúrate de que la ruta al archivo firebase es correcta

const useFetchAppData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const appRef = ref(database, 'app/YEYAJHNDYID87Ujsd');

    const unsubscribe = onValue(appRef, (snapshot) => {
      const appData = snapshot.val();
      if (appData) {
        // Asegúrate de que todos los campos están presentes y bien formateados
        setData({
          contact: appData.contact || {},
          description: appData.description || '',
          nameApp: appData.nameApp || '',
          social: appData.social || {}
        });
      } else {
        setData(null);
      }
      setLoading(false);
    }, (error) => {
      setError(error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { data, loading, error };
};

export default useFetchAppData;
