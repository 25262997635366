// src/components/EpaycoCheckout.js
import React, { useEffect } from 'react';

const EpaycoCheckout = () => {
  useEffect(() => {
    // Carga el script de Epayco si no está cargado aún
    const script = document.createElement('script');
    script.src = 'https://checkout.epayco.co/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Limpieza al desmontar el componente
    };
  }, []);

  const handlePayment = () => {
    const handler = window.ePayco.checkout.configure({
      key: 'f2fac805d8f8aa2dca0923f4dcd8d727',
      test: true, // Cambiar a false en producción
    });

    handler.open({
      amount: '10000', // Monto a pagar en COP
      name: 'Pago de Servicios para Mascotas',
      description: 'Baño y Peluquería para Mascotas',
      currency: 'cop',
      invoice: '12345', // Identificador único de la transacción
      extra1: 'ID_Cliente',
      extra2: 'Servicios adicionales',
      tax_base: '0', // Base imponible
      tax: '0', // IVA (opcional)
      country: 'CO',
      lang: 'es',
    });
  };

  return (
    <div>
      <h1>Pagar con Epayco</h1>
      <button onClick={handlePayment}>Pagar ahora</button>
    </div>
  );
};

export default EpaycoCheckout;
