import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, push, serverTimestamp } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FaCamera } from 'react-icons/fa';

export default function AdminSupportChat() {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [image, setImage] = useState(null); // Estado para la imagen
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAdmin(user);
        loadChats();
      } else {
        // Redirigir al login si no está autenticado
        // navigate('/login');
      }
    });

    return () => unsubscribeAuth(); // Limpieza del listener
  }, []);

  const loadChats = () => {
    const db = getDatabase();
    const chatsRef = ref(db, 'chats');
    onValue(chatsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const chatList = Object.entries(data)
          .map(([id, chat]) => ({
            id,
            lastMessage: Object.values(chat).pop(), // Obtiene el último mensaje
            unreadCount: admin ? Object.values(chat).filter(msg => !msg.read && msg.sender !== admin.uid).length : 0
          }))
          .sort((a, b) => b.lastMessage?.timestamp - a.lastMessage?.timestamp); // Ordena chats por timestamp descendente
        setChats(chatList);
      }
    });
  };

  const selectChat = (chatId) => {
    setSelectedChat(chatId);
    loadMessages(chatId);
  };

  const loadMessages = (chatId) => {
    const db = getDatabase();
    const messagesRef = ref(db, `chats/${chatId}`);
    onValue(messagesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const messageList = Object.values(data);
        setMessages(messageList);
      }
    });
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const uploadImage = async () => {
    if (image) {
      const storage = getStorage();
      const imageRef = storageRef(storage, `chat-images/${Date.now()}_${image.name}`);
      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);
      return imageUrl;
    }
    return null;
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() || image) {
      const imageUrl = await uploadImage();
      const db = getDatabase();
      const chatRef = ref(db, `chats/${selectedChat}`);
      push(chatRef, {
        sender: admin.uid,
        text: newMessage,
        timestamp: serverTimestamp(),
        userName: admin.displayName || 'Admin', // Aquí usas el nombre del administrador si lo tiene, sino 'Admin'
        read: false,
        imageUrl: imageUrl // Añadir URL de la imagen si existe
      });
      setNewMessage('');
      setImage(null); // Limpiar imagen después de enviar
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Lista de chats */}
      <div className="w-1/3 bg-white border-r border-gray-200 overflow-y-auto">
        <h2 className="text-xl font-bold p-4 bg-blue-600 text-white">Chats de Soporte</h2>
        {chats.map((chat) => (
          <div
            key={chat.id}
            onClick={() => selectChat(chat.id)}
            className={`flex items-center p-4 border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${
              selectedChat === chat.id ? 'bg-blue-50' : ''
            }`}
          >
            <div className="w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center text-white font-bold mr-3">
              {chat.lastMessage?.userName?.[0] || 'N/A'}
            </div>
            <div className="flex-1">
              <h3 className="font-semibold">{chat.lastMessage?.userName || 'Sin Nombre'}</h3>
              <p className="text-sm text-gray-500 truncate">{chat.lastMessage?.text || 'Sin mensaje'}</p>
            </div>
            {chat.unreadCount > 0 && (
              <div className="bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs">
                {chat.unreadCount}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Chat seleccionado */}
      {selectedChat ? (
        <div className="flex-1 flex flex-col">
          <div className="flex-1 overflow-y-auto p-4 space-y-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${
                  message.sender === admin?.uid ? 'justify-end' : 'justify-start'
                }`}
              >
                <div
                  className={`max-w-xs lg:max-w-md px-4 py-2 rounded-lg ${
                    message.sender === admin?.uid
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-200 text-gray-800'
                  }`}
                >
                  <div className="font-bold mb-1">{message.userName || 'Anónimo'}</div>
                  <p>{message.text}</p>
                  {message.imageUrl && (
                    <img
                      src={message.imageUrl}
                      alt="Imagen"
                      className="mt-2 max-w-xs rounded"
                    />
                  )}
                  <div className="text-xs opacity-75 text-right mt-1">
                    {message.timestamp
                      ? new Date(message.timestamp).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                      : ''}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <form onSubmit={sendMessage} className="bg-white p-4 border-t border-gray-200 pb-10">
            <div className="flex items-center rounded-lg border border-gray-300 overflow-hidden">
              <label className="flex items-center cursor-pointer p-2">
                <FaCamera size={24} color="blue" />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="hidden"
                />
              </label>
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Escribe tu respuesta..."
                className="flex-1 px-4 py-2 border-none focus:outline-none"
              />
              <button
                type="submit"
                className="bg-blue-500 text-white px-6 py-2 font-medium hover:bg-blue-600 transition duration-300 ease-in-out"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center text-gray-500">
          Selecciona un chat para ver los mensajes
        </div>
      )}
    </div>
  );
}
