import React from 'react';
import { TiArrowBack } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Navega a la página anterior
  };

  return (
    <button onClick={handleBackClick} className="text-white text-xl ">
      <TiArrowBack />
    </button>
  );
};

export default BackButton;
