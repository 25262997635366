import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { useNavigate } from 'react-router-dom';

const Products = ({ storeId }) => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const db = getDatabase();
    const productsRef = ref(db, `stores/${storeId}/procut`); // Asegúrate de que la ruta es correcta
 
    onValue(productsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const productsList = Object.keys(data).map(key => ({
          id: key,
          ...data[key]
        }));
        setProducts(productsList);
      } else {
        setProducts([]);
      }
    });
  }, [storeId]);

  const handleProductClick = (id) => {
    navigate(`/store/${storeId}/product/${id}`); // Utiliza 'id' en lugar de 'productId'
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold text-red-500">Nuestros Productos:</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        {products.length > 0 ? (
          products.map(product => (
            <div
              key={product.id}
              className="bg-gray-800 p-4 rounded-lg shadow-md cursor-pointer"
              onClick={() => handleProductClick(product.id)}
            >
              <img
                src={product.imgUrl}
                alt={product.nameProduct}
                className="w-full h-32 object-cover rounded-md mb-2"
              />
              <h3 className="text-xl font-semibold text-white mb-1">{product.nameProduct}</h3>
              <p className="text-gray-300 mb-1">{product.description}</p>
              <p className="text-yellow-400 font-bold mb-1">${product.price.toLocaleString()}</p>
              <p className="text-gray-400">Stock: {product.stock}</p>
            </div>
          ))
        ) : (
          <p className="text-gray-400">No hay productos disponibles.</p>
        )}
      </div>
    </div>
  );
};

export default Products;
