import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { database } from '../utils/firebase';
import { ref, get } from 'firebase/database';

export default function AdminDashboard() {
  const [store, setStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStore = async () => {
      const storeId = localStorage.getItem('storeId');
      if (!storeId) {
        navigate('/'); // Redirige al login si no hay ID de tienda en localStorage
        return;
      }

      try {
        const storeRef = ref(database, `stores/${storeId}`);
        const snapshot = await get(storeRef);
        if (snapshot.exists()) {
          setStore(snapshot.val());
        } else {
          console.error('No se encontró la tienda');
        }
      } catch (err) {
        console.error('Error al obtener los datos de la tienda:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchStore();
  }, [navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg">Cargando...</div>
      </div>
    );
  }

  if (!store) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg">No se pudo cargar la tienda</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100">
      {/* Banner */}
      <div className="bg-blue-600 text-white text-center py-4">
        <h1 className="text-4xl font-bold">Panel de Administración</h1>
      </div>

      {/* Menu de Navegación */}
      <nav className="bg-white shadow-md">
        <div className="container mx-auto flex justify-around py-2">
          <a href="#productos" className="text-lg text-gray-800 hover:text-blue-600">Productos</a>
          <a href="#servicios" className="text-lg text-gray-800 hover:text-blue-600">Servicios</a>
          <a href="#citas" className="text-lg text-gray-800 hover:text-blue-600">Citas</a>
          <a href="#reportes" className="text-lg text-gray-800 hover:text-blue-600">Reportes</a>
        </div>
      </nav>

      {/* Contenido Principal */}
      <div className="p-6 container mx-auto">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">{store.name}</h2>
          <img
            src={store.logoUrl}
            alt="Logo de la tienda"
            className="w-32 h-32 mb-4 rounded-full mx-auto"
          />
          <p className="text-lg mb-4"><strong>Email:</strong> {store.email}</p>
          <p className="text-lg mb-4"><strong>Teléfono:</strong> {store.contact}</p>
          <p className="text-lg mb-4"><strong>Descripción:</strong> {store.description}</p>
          <p className="text-lg mb-4"><strong>Ubicación:</strong> {store.location}</p>
          <p className="text-lg mb-4"><strong>Horas de Atención:</strong> {store.hours}</p>

          <h3 id="productos" className="text-xl font-semibold mt-6 mb-4">Productos</h3>
          <ul>
            {store.product && Object.values(store.product).map((product) => (
              <li key={product.id} className="mb-4 border-b border-gray-300 pb-2">
                <img
                  src={product.imgUrl}
                  alt={product.nameProduct}
                  className="w-24 h-24 mb-2"
                />
                <p className="text-lg font-semibold">{product.nameProduct}</p>
                <p className="text-lg">Precio: ${product.price}</p>
                <p className="text-lg">Stock: {product.stock}</p>
                <p className="text-lg">Descripción: {product.description}</p>
              </li>
            ))}
          </ul>

          <h3 id="servicios" className="text-xl font-semibold mt-6 mb-4">Servicios</h3>
          <ul>
            {store.service && Object.values(store.service).map((service) => (
              <li key={service.id} className="mb-4 border-b border-gray-300 pb-2">
                <img
                  src={service.imgUrl}
                  alt={service.name}
                  className="w-24 h-24 mb-2"
                />
                <p className="text-lg font-semibold">{service.name}</p>
                <p className="text-lg">Precio: ${service.price}</p>
                <p className="text-lg">Stock: {service.stock}</p>
                <p className="text-lg">Descripción: {service.description}</p>
              </li>
            ))}
          </ul>

          <div id="citas" className="mt-6">
            {/* Aquí puedes agregar contenido relacionado con citas */}
            <h3 className="text-xl font-semibold mb-4">Citas</h3>
            <p>Aquí se gestionarán las citas.</p>
          </div>

          <div id="reportes" className="mt-6">
            {/* Aquí puedes agregar contenido relacionado con reportes */}
            <h3 className="text-xl font-semibold mb-4">Reportes</h3>
            <p>Aquí se mostrarán los reportes.</p>
          </div>

          <div className="mt-6">
            <button
              onClick={() => {
                localStorage.removeItem('storeId');
                navigate('/'); // Redirige al login
              }}
              className="px-4 py-2 bg-red-600 text-white rounded-lg"
            >
              Cerrar sesión
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
