import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (showError) {
      const timer = setTimeout(() => {
        setShowError(false);
      }, 5000); // Mostrar el error por 5 segundos

      return () => clearTimeout(timer);
    }
  }, [showError]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setError(''); // Limpiar cualquier mensaje de error anterior
    setShowError(false);

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        // Guardar el UUID en localStorage
        localStorage.setItem('userUUID', user.uid);

        // Inicio de sesión exitoso, redirigir a la página principal
        navigate('/home'); // Ajusta la ruta según tu estructura de rutas
      })
      .catch((error) => {
        if (error.code === 'auth/user-not-found') {
          setError('No se encontró un usuario con este correo electrónico.');
        } else if (error.code === 'auth/wrong-password') {
          setError('La contraseña es incorrecta.');
        } else {
          setError('Ocurrió un error al iniciar sesión. Inténtalo de nuevo.');
        }
        setShowError(true);
        console.error('Error iniciando sesión:', error);
      });
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: 'url("https://t1.uc.ltmcdn.com/es/posts/1/5/4/como_se_forman_las_nubes_19451_600.jpg")' }}
    >
      <div
        className="relative w-full max-w-md p-10 space-y-8 rounded-3xl shadow-2xl bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: 'url("https://www.semana.com/resizer/AfpbiiobE_ZuLLKWy9ZMnhkWhjA=/arc-anglerfish-arc2-prod-semana/public/2CQBVNCQBFA37I7A3ROSV6OBXY.jpg")' }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 rounded-3xl"></div>
        <div className="relative z-10">
          <h1 className="text-4xl font-extrabold text-center text-white">Inicia sesión</h1>
          <form onSubmit={handleSubmit}>
            {showError && (
              <div className="mb-4 p-4 text-red-600 bg-red-100 border border-red-400 rounded-lg shadow-md animate-bounce">
                {error}
              </div>
            )}
            <div className="mb-6">
              <label htmlFor="email" className="block mb-2 text-sm font-semibold text-white">
                Correo Electrónico
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-5 py-3 text-gray-700 bg-gray-100 border border-gray-200 rounded-lg shadow-sm focus:outline-none focus:ring-4 focus:ring-blue-400"
                placeholder="correo@ejemplo.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-8">
              <label htmlFor="password" className="block mb-2 text-sm font-semibold text-white">
                Contraseña
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-5 py-3 text-gray-700 bg-gray-100 border border-gray-200 rounded-lg shadow-sm focus:outline-none focus:ring-4 focus:ring-blue-400"
                placeholder="********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="flex items-center justify-between mb-6">
              <Link to="/forgot-password" className="text-sm text-blue-400 hover:underline">
                ¿Olvidaste tu contraseña?
              </Link>
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="w-full px-6 py-3 text-lg font-medium text-white transition-all duration-200 bg-blue-600 rounded-full shadow-lg hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300"
              >
                Iniciar sesión
              </button>
            </div>
            <div className="mt-6 text-center">
              <Link to="/create-account" className="text-sm text-blue-400 hover:underline">
                ¿No tienes cuenta? Crea una
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
