import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';
import Loading from '../utils/Loading';

const ProductDetails = () => {
  const { storeId, id } = useParams(); // Asegúrate de que 'storeId' y 'id' están en los parámetros de la ruta
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Cargar el script de Epayco
    const script = document.createElement('script');
    script.src = 'https://checkout.epayco.co/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const productRef = ref(db, `stores/${storeId}/procut/${id}`); // Corregir la ruta de la referencia

    onValue(productRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setProduct(data);
      }
      setLoading(false); // Desactiva el estado de carga una vez que los datos han sido recuperados
    }, (error) => {
      console.error('Error al obtener el producto:', error);
      setLoading(false); // Asegúrate de desactivar el estado de carga en caso de error
    });
  }, [storeId, id]);

  // Función para manejar el pago usando Epayco
  const handlePayment = () => {
    const handler = window.ePayco.checkout.configure({
      key: 'f2fac805d8f8aa2dca0923f4dcd8d727', // Reemplazar con tu key pública
      test: true, // Cambiar a false en producción
    });

    handler.open({
      amount: product.price.toString(), // Monto a pagar
      name: product.nameProduct,
      description: product.description,
      currency: 'cop', // Moneda en pesos colombianos
      invoice: `${product.id}-${Date.now()}`, // Identificador único
      tax_base: '0', // Base imponible
      tax: '0', // IVA (opcional)
      country: 'CO', // País
      lang: 'es', // Lenguaje
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (!product) {
    return <p className="text-gray-400">Producto no encontrado.</p>;
  }

  return (
    <div className="min-h-screen bg-gray-900 text-white p-6">
      <div className="bg-gray-900 rounded-md shadow-md">
        <h2 className="text-3xl font-bold text-red-500 mb-4">{product.nameProduct}</h2>
        <img src={product.imgUrl} alt={product.nameProduct} className="w-full h-80 object-cover rounded-md mb-6" />
        <p className="text-gray-200 mb-4">{product.description}</p>
        <p className="text-yellow-400 text-2xl font-bold mb-4">${product.price.toLocaleString()}</p>
        <p className="text-gray-300 mb-6">Stock disponible: {product.stock}</p>
        <div className="flex space-x-4">
          <button
            className="px-4 py-2 bg-green-500 text-white font-semibold rounded-md hover:bg-green-600 transition"
            onClick={handlePayment}
          >
            Comprar Ahora
          </button>
          <button className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition">
            Reservar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
