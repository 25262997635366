import React from 'react';
import { Link } from 'react-router-dom';
import useFetchAppData from '../hooks/useFetchAppData'; // Asegúrate de que la ruta es correcta
import Loading from '../utils/Loading'; // Asegúrate de que la ruta es correcta

function About() {
  const { data, loading, error } = useFetchAppData();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-r from-teal-400 via-blue-500 to-purple-600 text-white">
      <header className="flex justify-between items-center p-4 bg-gray-900 bg-opacity-70 text-white">
        <h1 className="text-3xl font-bold">Acerca de</h1>
        <nav className="flex space-x-4">
          <Link to="/">
            <button className="py-2 px-4 bg-gray-600 hover:bg-gray-700 text-white font-semibold rounded-lg shadow-lg transition-transform transform hover:scale-105">
              Inicio
            </button>
          </Link>
        </nav>
      </header>

      <main className="flex-grow p-4">
        <h2 className="text-3xl font-bold mb-4">Acerca de Nuestra Aplicación</h2>
        <p className="mb-4">
          {data?.description || 'Descripción no disponible'}
        </p>
        <p className="mb-4">
          Características principales:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Buscar productos y servicios para tus mascotas.</li>
          <li>Descubrir las mejores tiendas de mascotas en tu área.</li>
          <li>Acceder a una amplia gama de productos para el cuidado y bienestar de tus mascotas.</li>
          <li>Solicitar asistencia de emergencia de manera rápida y eficaz.</li>
        </ul>
        <p>
          Nuestro objetivo es mejorar la experiencia de ser dueño de una mascota, haciendo que el cuidado y la gestión de sus necesidades sean más fáciles y accesibles.
        </p>
      </main>

      <footer className="bg-gray-800 text-center py-4 text-white mt-auto">
        <p>&copy; {new Date().getFullYear()} {data?.nameApp || 'Nuestra App'}. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
}

export default About;
